import { Glyphicon } from 'react-bootstrap'
import { BookingDatePicker } from './booking-date-picker'
import { BookingTimePicker } from './booking-time-picker'

export const DateTimeColumn = ({ 
    props, 
    className, 
    showBooking, 
    showCallback,
    representativeHasMaxBookings,
    representative,
    t,
    disabled,
    setCallbackStartsAt,
    callbackStartsAt,
    setUserModified,
    typeOfBooking,
    setInvalidTime
}) => {

    const wrapperStyle = showBooking ? {flex: '0 1 25%'} : {}
    const bodyStyle = disabled ? {pointerEvents: 'none', cursor: 'not-allowed', opacity: .5} : {}
    const title = showCallback ? 'callback' : 'booking.booking'
    const rowClassName = showBooking ? "picker-row booking" : "picker-row"

    return (
        <div className={className} style={wrapperStyle}>
            <div className="heading">{t(title)}</div>
            <div style={bodyStyle}>
              <div className={rowClassName}>
                <Glyphicon glyph="calendar" />
                <BookingDatePicker
                  date={callbackStartsAt}
                  props={props}
                  showBooking={showBooking}
                  representative={representative}
                  representativeHasMaxBookings={representativeHasMaxBookings}
                  setCallbackStartsAt={setCallbackStartsAt}
                  setUserModified={setUserModified}
                  showCallback={showCallback}
                />
              </div>
              <div className={rowClassName}>
                <Glyphicon glyph="time" />
                <BookingTimePicker
                  props={props}
                  date={callbackStartsAt}
                  disabled={disabled}
                  showBooking={showBooking}
                  representative={representative}
                  representativeHasMaxBookings={representativeHasMaxBookings}
                  setCallbackStartsAt={setCallbackStartsAt}
                  setUserModified={setUserModified}
                  typeOfBooking={typeOfBooking}
                  setInvalidTime={setInvalidTime}
                />
              </div>
            </div>
        </div>
    )
}