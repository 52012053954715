import React from 'react'
import { DropdownButton, MenuItem } from 'react-bootstrap'
import { getDefaultBookingTypeForRepresentative } from './utils'

export const ServicePointDropdown = ({
    props, 
    disabled, 
    typeOfBooking, 
    setTypeOfBooking,
    servicePoint,
    setServicePoint,
    setRepresentative
}) => {

    const title = servicePoint?.name || 'Valitse toimipiste...'

    const onSelect = servicePoint => {
        const representative = servicePoint.representatives[0]
        if ( representative && props.contact.project.businessCheckBookingValidity ) {
            const defaultTypeOfBooking = getDefaultBookingTypeForRepresentative(representative, props, typeOfBooking)
            setTypeOfBooking(defaultTypeOfBooking)
        }
        setRepresentative(representative)
        setServicePoint(servicePoint)
    }

    return <DropdownButton disabled={disabled} id="select-service-point" title={title}>
        {props.servicePoints.map(_servicePoint => 
            <MenuItem
                active={_servicePoint.id === servicePoint?.id}
                eventKey={_servicePoint}
                key={_servicePoint.id}
                onSelect={_servicePoint => onSelect(_servicePoint)}
            >
                <span>{_servicePoint.name}</span>
            </MenuItem>
        )}
    </DropdownButton>

}