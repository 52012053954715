import { ContactBookingList } from '../../components';

export const BookingList = ({ contact, isBusinessCallFlow, t }) => {

    const wrapperStyle = {
        width: '100%', 
        padding: 10, 
        margin: '5px 20px 20px 10px', 
        boxShadow: '1px 1px 3px rgba(0,0,0,.2)',
        borderRadius: 10,
        border: 0
    }

    const titleStyle = { fontSize: 20 }

    if ( !contact?.bookings?.length ) return null
    else return <div className="contact">
        <div className="bookings" style={wrapperStyle}>
            <div className="title" style={titleStyle}>
                {t('booking.bookings')}
            </div>
            <ContactBookingList 
                contact={contact} 
                reloadOnCancelOrDelete={isBusinessCallFlow}
                businessCallFlow={isBusinessCallFlow}
            />
        </div>
    </div>
    
}

export default BookingList;