import { api } from "./api"

export const representativesApi = api.injectEndpoints({
	endpoints: (builder) => ({
        getSuggestionsForRepresentative: builder.query({
            query: ({representativeId, params}) => ({
                url: `/representatives/${representativeId}/suggestions`,
                params
            }),
            providesTags: (res, err, opts) => [{ 
                type: "RepresentativeSuggestions", 
                id: opts.representativeIdId
            }]
		}),
        getRepresentativeBookingTimeValidation: builder.query({
            query: ({representativeId, params}) => ({
                url: `/representatives/${representativeId}/is-booking-time-valid`,
                params
            }),
            providesTags: (res, err, opts) => [{ 
                type: "RepresentativeBookingTimeValidation", 
                id: opts.representativeIdId
            }]
		})
	})
})

export const { 
	useGetSuggestionsForRepresentativeQuery,
    useGetRepresentativeBookingTimeValidationQuery
} = representativesApi