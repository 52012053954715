import sortBy from 'lodash/sortBy';
import moment from 'moment';
import React, { useState, useEffect, useContext, forwardRef, useImperativeHandle } from 'react';
import { Button } from 'react-bootstrap';
import { connect } from 'react-redux';

import * as contactActions from '../../redux/contacts/actions';
import * as bookingActions from '../../redux/booking/actions';

import { DateTimeColumn } from './date-time-column';
import { RepresentativeColumn } from './representative-column';
import { BookingList } from './booking-list';
import { ParticipantTags } from './participant-tags';
import { BookingStatusesDropdown } from './booking-statuses-dropdown';
import { BookingSubStatusesDropdown } from './booking-substatuses-dropdown';
import { ContactStatusDropdown } from './contact-status-dropdown';
import { BlocklistModal } from './blocklist-modal';
import { ContactNotes } from './contact-notes';

import { useLazyGetStatisticsForRepresentativeQuery } from '../../rtk/statistics-api';

import { 
  isBusinessBookingCallback as _isBusinessBookingCallback,
  isBusinessBookingContact as _isBusinessBookingContact,
  isBookingInBusinessUi,
  isBusinessBookingCancelled,
  isBusinessBookingMade,
  getServicePoint,
  getSubStatusesById,
  getEmailTitle,
  getEmailBody,
  getTextMessage,
  getAdjacentRepresentativesByRepresentativeId,
  getDefaultBookingTypeForRepresentative,
  getSelectedSubAreaForRepresentative,
  getSelectedServicePointForRepresentative
} from './utils';

import { CONTACT_BOOKING_IDS, BUSINESS_NOSHOW_BOOKING_IDS } from '../../utils';
import { SendEmailInvitations } from './send-email-invitations';
import { SendSmsConfirmations } from './send-sms-confirmations';

import { useGetContactStatusesQuery } from "../../rtk/contacts-api"

import ContactStatusContext from './contact-status-context';

const ContactStatus = forwardRef((props, ref) => {

  const context = useContext(ContactStatusContext)

  const [getStatisticsForRepresentative] = useLazyGetStatisticsForRepresentativeQuery()

  const [contactStatusOpen, setContactStatusOpen] = useState(
    props.currentCallType == null
      && props.parent?.props?.location?.hash === '#open'
  )

  let { data: contactStatuses, isLoading, isFetching } = useGetContactStatusesQuery(
    { withBooking: props.withBooking, contactId: props.contact?.id, callType: props.currentCallType }, 
    { skip: !props.contact, refetchOnMountOrArgChange: true }
  )

  isLoading = isLoading || isFetching
  
  contactStatuses = contactStatuses 
    ? sortBy(contactStatuses, 'orderNr') 
    : []

  const [invalidTime, setInvalidTime] = useState(false)

  /* state.status */
  const [status, setStatus] = useState(null)

  /* state.contact */
  const [callbackNotes, setCallbackNotes] = useState(null)
  const [callbackStartsAt, setCallbackStartsAt] = useState(null)
  const [contactNotes, setContactNotes] = useState(null)

  /* state.userModified */
  const [userModified, setUserModified] = useState(false)

  /* state.textMessage, state.sendTextMessage */
  const [textMessage, setTextMessage] = useState('')
  const [sendTextMessage, setSendTextMessage] = useState(
    props.project ? !props.project.disableSendSmsByDefault : true
  )

  /* state.emailTitle, state.emailBody, state.sendEmail */
  const [emailTitle, setEmailTitle] = useState('')
  const [emailBody, setEmailBody] = useState('')
  const [sendEmail, setSendEmail] = useState(true)

  /* state.typeOfBooking */
  const [typeOfBooking, setTypeOfBooking] = useState('servicePoint')

  /* state.notes */
  const [notes, setNotes] = useState('')

  /* state.representativeHasMaxBookings */
  const [representativeHasMaxBookings, setRepresentativeHasMaxBookings] = useState(false)

  /* state.servicePoint */
  const [servicePoint, setServicePoint] = useState(props.servicePoint)

  /* state.representative */
  const [representative, setRepresentative] = useState(props.servicePoint.representatives?.[0])

  /* state.subArea */
  const [subArea, setSubArea] = useState(null)

  /* state.showBlockListModal */
  const [showBlockListModal, setShowBlockListModal] = useState(null)

  const subStatuses = contactStatuses.length 
    ? getSubStatusesById(
      context.status?.parentStatusId || context.status?.id, 
      contactStatuses
    ) : []

  useEffect(() => {

    if ( userModified ) return

    /* state.status */
    context.setStatus(props.contact.status)

    /* state.contact */
    setContactNotes(props.contact.contactNotes || '')
    setCallbackNotes(props.contact.callbackNotes || '')

    const newCallbackStartsAt = props.bookingStartsAt || props.contact.callbackStartsAt
    setCallbackStartsAt(
      newCallbackStartsAt
        ? moment(newCallbackStartsAt).toDate() 
        : moment().toDate()
    )

    /* state.representative, state.subArea, state.servicePoint */
    const newRepresentativeId = props.bookingRepresentativeId || props.contact.representativeId
    const newRepresentative = getAdjacentRepresentativesByRepresentativeId(
      newRepresentativeId, props
    ).find(representative => 
      representative.id === newRepresentativeId
    )
    if ( newRepresentative ) {
      if ( props.project.mergeServicePoints ) {
        setSubArea(getSelectedSubAreaForRepresentative(newRepresentative.id, props))
        const newServicePoint = getSelectedServicePointForRepresentative(newRepresentative.id, props)
        setServicePoint(newServicePoint)
        setRepresentative({
          ...newRepresentative,
          servicePoint: {
            ...newServicePoint,
            representatives: undefined
          }
        })
      } else {
        setServicePoint(newRepresentative.servicePoint)
        setRepresentative(newRepresentative)
      }  
    }

    /* state.bookingType */
    setTypeOfBooking(
      props.bookingType || 
      getDefaultBookingTypeForRepresentative(
        representative, 
        props, 
        typeOfBooking
      )
    )

    /* state.contactNotes */
    setContactNotes(props.contact.contactNotes || '')

    /* state.callbackNotes */
    setCallbackNotes(props.contact.callbackNotes || '')

    /* state.servicePoint */
    setServicePoint(
      getServicePoint(props) ||
      props.servicePoints.find(
        servicePoint => servicePoint.id === props.contact.servicePointId
      )
    )

    /* state.bookingStatusId */
    context.setBookingStatusId(props.bookingStatusId)

    /* state.notes */
    setNotes(props.bookingNotes || '')

  }, [props.contact, userModified])

  useEffect(() => {

    setUserModified(false)
    if ( props.parent ) {
      context.setUserModified(false)
    }

  }, [props.contact?.id])

  useEffect(() => {

    if ( props.call && 
      props.call.active && 
      !props.call.origin && 
      !props.currentCallType 
    ) {
      setContactStatusOpen(true)
    }

  }, [props.call])

  const handleNotesChange = (event, key='callbackNotes') => {
    if ( key === 'callbackNotes' ) {
      setCallbackNotes(event.target.value)
    } else if ( key === 'contactNotes' ) {
      setContactNotes(event.target.value)
    }
    setUserModified(true)
    if ( props.parent ) {
      context.setUserModified(true)
    }  
  }

  const handleClickSave = () => {
    if (context.status.superType === 'blockList') {
      setShowBlockListModal(true)
      return
    } else if ( isBookingInBusinessUi(props) && context.status.superType === 'booking' ) {
      if ( props.parent ) {
        context.setSavePending(true)
      }
      if ( props.bookingId && BUSINESS_NOSHOW_BOOKING_IDS.includes(context.bookingStatusId) ) {
        if ( context.bookingStatusId === props.bookingStatusId ) {
          props.getContact(props.contact.id)
        } else {
          props.setBookingStatus(
            props.bookingId, 
            context.bookingStatusId, 
            props.contact.id
          )
        }
        return
      }
      props.reserveBookingFinal(
        representative.id, 
        props.contact.id, 
        callbackStartsAt,
        props.tags.map(tag => tag.id),
        sendEmail && props.project.enableEmailInvitations,
        emailTitle,
        emailBody,
        sendTextMessage,
        textMessage,
        typeOfBooking,
        notes,
        props.contact.status?.superType === 'noShow'
          ? props.bookingId
          : null
      )
    } else {      
      if ( props.parent ) {
        context.setSavePending(true)
      }
      let publicNotes = null
      if ( context.status.superType === 'callback' ) {
        publicNotes = callbackNotes
      } else if ( context.status.superType === 'contact' ) {
        publicNotes = contactNotes
      }
      props.setContactStatus(
        props.contact.id,
        publicNotes,
        callbackStartsAt,
        context.status.id,
        context.status.superType,
        false,
        null,
        props.confirmationBookingId,
        props.project.customUserInterfaceId === 2 
          ? props.tags.map(tag => tag.id)
          : null,
        context.status.superType === 'callback' && props.switchChecked
      );
    }
  }

  useImperativeHandle(ref, () => ({
    handleClickSave
  }))

  const closeModal = block => {
    if (block) {
      if ( props.parent ) {
        context.setSavePending(true)
      }
      const mainStatusId = context.status?.parentStatusId || context.status?.id
      props.setContactStatus(
        props.contact.id, '', '',
        context.status.id, 'blockList', !props.callFlow,
        {
          ...props.contact,
          status: contactStatuses.find(
            status => status.id === mainStatusId
          )
        },
        props.confirmationBookingId
      );
    }
    setShowBlockListModal(false)
  }

  const toggle = () => {
    setContactStatusOpen(!contactStatusOpen)
  }

  const getParticipantTagComponent = (title, placeholder, includeSwitch=false) => {
    return <ParticipantTags
      props={props}
      title={title}
      placeholder={placeholder}
      includeSwitch={includeSwitch}
      bookingStatusId={context.bookingStatusId}
    />
  }

  const hasSubStatuses = subStatuses.length > 0
  const isCallback = context.status?.superType === 'callback'
  const showBooking = ['noShow', 'booking'].includes(context.status?.superType) && props.withBooking

  const isBusinessBookingContact = _isBusinessBookingContact(props, context.status?.parentStatusId || context.status?.id)
  const isBusinessBookingCallback = _isBusinessBookingCallback(props, isCallback)
  const isBusinessBookingContactOrCallback = isBusinessBookingContact || isBusinessBookingCallback

  const outerWrapperStyle = props.callFlow && ( showBooking || isBusinessBookingContactOrCallback )
    ? { borderBottom: 0, paddingBottom: isBusinessBookingContactOrCallback || props.project.businessAllowServicePointChange ? 0 : 20 } : {}

  const wrapperStyle = showBooking ? {flex: '0 1 25%'} : {}

  const wrapperClassName = "status-column" + ( props.parent !== undefined ? ` status-column-wide ${(!hasSubStatuses && !isCallback && !showBooking) ? "status-column-wide-single" : ""}` : "")

  const disabledBusinessBooking = isBusinessBookingCancelled(props, context.bookingStatusId) || isBusinessBookingMade(props)

  const refreshTemplates = async () => {
    if ( showBooking && callbackStartsAt ) {
      const textMessage = getTextMessage(props, representative, callbackStartsAt)
      if ( textMessage ) setTextMessage(textMessage)
      const emailTitle = getEmailTitle(props, representative, callbackStartsAt)
      if ( emailTitle ) setEmailTitle(emailTitle)
      const emailBody = getEmailBody(props, representative, callbackStartsAt)
      if ( emailBody ) setEmailBody({emailBody})
      
      const date = moment(callbackStartsAt)
      const projectId = getServicePoint(props).projectId
      if ( representative && !props.bookingId ) {
        try {
          const representativeId = representative.id
          const params = {
            date: moment(date).toISOString(),
            endDate: moment(date).toISOString(),
            timezone: 'Europe/Helsinki',
            projectId
          }
          const { maxBookingCount, n } = await getStatisticsForRepresentative({representativeId, params}).unwrap()
          setRepresentativeHasMaxBookings(n[0].count >= maxBookingCount)
        } catch ( e ) {
          console.error(e)
          setRepresentativeHasMaxBookings(false)
        }
      }
    }
  }

  useEffect(() => {
    refreshTemplates()
  }, [
    context.status, 
    callbackStartsAt, 
    representative, 
    servicePoint, 
    subArea, 
    typeOfBooking
  ])

  const isSaveDisabled = props => {
    let val;

    const mainStatusId = context.status?.parentStatusId || context.status?.id

    if (isBookingInBusinessUi(props) && CONTACT_BOOKING_IDS.includes(mainStatusId)) {
      const missingRequiredContactData = props.contact.projectAdditionalContactInfo
        .filter(a => a.required)
        .find(a => 
            !props.contact.contactData.find(b => b.title === a.title) ||
            !props.contact.contactData.find(b => b.title === a.title).value
        );

      if (missingRequiredContactData) {
        val = true;
      } else if (
        !representative || 
        props.tags.length === 0 || 
        (props.contact.project.businessCheckBookingValidity && 
        (invalidTime || representativeHasMaxBookings) &&
        !BUSINESS_NOSHOW_BOOKING_IDS.includes(context.bookingStatusId))
      ) {
        val = true;
      } else {
        val = false;
      }
    } else if ( context.status?.subStatuses?.length > 0 ) {
      // Only parent status selected
      val = true;
    } else if ( isCallback && !callbackNotes ) {
      // Status is callback but notes is empty
      val = true;
    } else if (!isCallback && 
        props.project.customUserInterfaceId === 2 &&
        mainStatusId === 2
      ) {
      val = false;
    }/* else if (!isCallback && !allowSave) {
      // Open or booked status
      val = true;
    }*/ else {
      val = false;
    }
    if ( val != context.isSaveDisabled ) {
      context.setIsSaveDisabled(val)
    }
    return val;
  }

  return <>
    <div className="contact-status" style={outerWrapperStyle}>
      <div style={wrapperStyle} className={wrapperClassName}>
        <div className="heading">{props.t('status.status')}</div>
        <ContactStatusDropdown onToggle={toggle}
          props={props}
          open={contactStatusOpen}
          contactStatuses={contactStatuses}
          isLoading={isLoading}
          setStatus={context.setStatus}
          status={context.status}
          setUserModified={setUserModified}
        />
        {
          showBooking &&
          <div style={{ marginTop: 10 }}>
            <BookingStatusesDropdown 
              props={props}
              setUserModified={setUserModified}
              bookingStatusId={context.bookingStatusId}
              setBookingStatusId={context.setBookingStatusId}
            />
          </div>
        }
      </div>
      {hasSubStatuses && (
        <div className={ "status-column" + (props.parent !== undefined ? " status-column-wide status" : "") }>
          <div className="heading">{props.t('status.specify')}</div>
          <BookingSubStatusesDropdown
            onSelect={status => {
              context.setStatus(status)
              setUserModified(true)
            }}
            contactStatuses={contactStatuses}
            status={context.status}
          />
        </div>
      )}
      {
        ( isCallback || showBooking ) &&
          <DateTimeColumn
            setInvalidTime={setInvalidTime}
            props={props}
            className={"status-column" + (props.parent !== undefined ? " status-column-wide status" : "")} 
            showBooking={showBooking} 
            showCallback={isCallback}
            representativeHasMaxBookings={representativeHasMaxBookings}
            representative={representative}
            t={props.t}
            disabled={disabledBusinessBooking}
            setCallbackStartsAt={setCallbackStartsAt}
            callbackStartsAt={callbackStartsAt}
            setUserModified={setUserModified}
            typeOfBooking={typeOfBooking}
          />
      }
      {showBooking && (
        <RepresentativeColumn 
          props={props}
          className={"status-column" + (props.parent !== undefined ? " status-column-wide status" : "")}
          t={props.t}
          disabled={disabledBusinessBooking}
          callbackStartsAt={callbackStartsAt}
          typeOfBooking={typeOfBooking}
          setTypeOfBooking={setTypeOfBooking}
          servicePoint={servicePoint}
          setServicePoint={setServicePoint}
          representative={representative}
          setRepresentative={setRepresentative}
          bookingStatusId={context.bookingStatusId}
          subArea={subArea}
          setSubArea={setSubArea}
        />
      )}
      {isCallback && (
        <div className={ "status-column" + (props.parent !== undefined ? " status-column-wide status" : "") }>
          <div className="heading">{props.t('notes')}</div>
          <textarea
            value={callbackNotes}
            onChange={event => handleNotesChange(event)}
          />
        </div>
      )}
      <div style={{display: props.parent === undefined ? "flex" : "none"}} className="status-column save">
        <Button
          bsStyle="primary"
          className="save"
          disabled={isSaveDisabled(props)}
          onClick={() => handleClickSave()}
        >
          {props.t('save')}
        </Button>
      </div>
    </div>
    { isBusinessBookingContactOrCallback && props.callFlow &&
      <div className="reservation" style={{ display: 'flex', flexFlow: 'column', borderBottom: isBusinessBookingContact ? '' : 'solid 1px #d6d6d6' }}>
        { getParticipantTagComponent('Päättäjät', 'Lisää päättäjiä...', isBusinessBookingCallback) }
      </div>
    }
    { isBusinessBookingContact && props.callFlow &&
      <div className="reservation" style={{ display: 'flex', flexFlow: 'column', borderBottom: 'solid 1px #d6d6d6' }}>
        <div className="info-row" style={{width: '100%', borderBottom: 0, paddingTop: 0}}>
          <ContactNotes
            addTopMargin={props.project.enableEmailInvitations}
            value={contactNotes}
            disabled={disabledBusinessBooking}
            onChange={event => handleNotesChange(event, 'contactNotes')}
          />
        </div>
      </div>
    }
    { showBooking &&
        <div className="reservation" style={{ display: 'flex', flexFlow: 'column' }}>
          { props.project.businessAllowServicePointChange && props.callFlow && 
            <>
              {getParticipantTagComponent('Osallistujat', 'Lisää osallistujia...')}
              <BookingList 
                contact={props.contact}
                isBusinessCallFlow={props.project?.customUserInterfaceId === 2}
                t={props.t}              
              />
            </>
          }
          { !props.project.businessAllowServicePointChange && props.callFlow && 
            <BookingList 
              contact={props.contact}
              isBusinessCallFlow={props.project?.customUserInterfaceId === 2}
              t={props.t}              
            />
          }
          <div className="info-row" style={{
            width: '100%', flexFlow: 'row', flexWrap: 'wrap', paddingTop: 4,
            ...(disabledBusinessBooking 
              ? {pointerEvents: 'none', cursor: 'not-allowed', opacity: .5}
              : {}
            )
          }}>
          <SendSmsConfirmations
            disabled={disabledBusinessBooking}
            textMessage={textMessage}
            setTextMessage={setTextMessage}
            sendTextMessage={sendTextMessage}
            setSendTextMessage={setSendTextMessage}
          />
          { props.project.enableEmailInvitations &&
            <SendEmailInvitations 
              props={props}
              disabled={disabledBusinessBooking}
              emailTitle={emailTitle}
              setEmailTitle={setEmailTitle}
              emailBody={emailBody}
              setEmailBody={setEmailBody}
              sendEmail={sendEmail}
              setSendEmail={setSendEmail}
            />
          }
          <ContactNotes
            addTopMargin={props.project.enableEmailInvitations}
            value={notes}
            disabled={disabledBusinessBooking}
            onChange={event => setNotes(event.target.value)}
          />
        </div>
      </div>
      }
      <BlocklistModal onClose={closeModal} 
        show={showBlockListModal} 
        t={props.t}
      />
    </>
})

const mapStateToProps = state => ({
  call: state.call.call,
  contact: state.contacts.contact || {},
  includeNotInterested: state.callLists.includeNotInterested,
  onlyOwn: state.callLists.onlyOwn,
  status: state.contacts.contact.status,
  user: state.user,
  currentCallType: state.callLists.current,
  project: state.contacts.contact?.project || {},
  client: state.call.selectedClient,
  servicePoint: state.call.selectedServicePoint,
  servicePoints: state.servicePoints.servicePoints,
  suggestions: state.call.suggestions.suggestions || [],
  subAreas: state.projects.subAreas
});

const mapDispatchToProps = dispatch => ({
  getContact: contactId => {
    dispatch(contactActions.getContact({contactId}));
  },
  setBookingStatus: (bookingId, statusId, getContactId) => {
    dispatch(bookingActions.setBookingStatus({bookingId, statusId, getContactId}));
  },
  reserveBookingFinal: (representativeId, contactId, startsAt, participantIds, sendEmail, emailTitle, emailBody, sendTextMessage, textMessage, type, notes, bookingId) => {
    dispatch(bookingActions.reserveBooking({
      representativeId, contactId, startsAt, final: true, participantIds, sendEmail, emailTitle, emailBody, sendTextMessage, textMessage, type, notes, bookingId
    }))
  },
  setContactStatus: (
    contactId, 
    publicNotes, 
    startsAt, 
    statusId, 
    superType, 
    noNextAfterBlock=false, 
    contact=null, 
    confirmationBookingId=null, 
    participantIds=null,
    switchChecked=false
  ) => {
    dispatch(
      contactActions.setContactStatus({
        contactId,
        publicNotes,
        startsAt,
        statusId,
        superType,
        noNextAfterBlock,
        contact,
        confirmationBookingId,
        participantIds,
        switchChecked
      })
    );
  },
});

export default connect(mapStateToProps, mapDispatchToProps, null, {forwardRef: true})(ContactStatus)
