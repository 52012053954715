import moment from 'moment';
import {
  faPlus,
  faPhone,
  faPhoneSlash,
  faQuestion,
  faRedoAlt,
  faCheck,
  faTimes,
  faStarHalf,
  faStar,
  faCalendarCheck,
  faCalendarAlt,
  faTimesCircle
} from '@fortawesome/free-solid-svg-icons';

const faClasses = {
  free: faPlus,
  created: faPhone,
  notConfirmed: faPhoneSlash,
  outcomeUnmarked: faQuestion,
  rescheduled: faRedoAlt,
  deal: faCheck,
  noDeal: faTimes,
  dealProposed: faStarHalf,
  followUp: faStar,
  reserved: faCalendarCheck,
  tentative: faCalendarAlt,
  noShow: faTimesCircle,
  personal: null
};

export const contactStatusToColor = status => {
  const a = .75,
    rgb = (r,g,b) => `rgba(${r},${g},${b},${a})`
  switch(status?.superType) {
    case 'open':
      return rgb(255,255,255)
    case 'callback':
      return rgb(64,64,200)
    case 'notTargetGroup':
    case 'noShow':
    case 'contact':
      return rgb(200,64,64)
    case 'wrongNumber':
    case 'blockList':
      return rgb(127,127,127)
    case 'booking':
    case 'tentative':
      return rgb(64,200,64)
    default:
      return rgb(200,200,200)
  }
}

/**
 * Returns the font-awesome icon for the given styleType
 */
export const eventTypeToFaIcon = type => (faClasses[type] || null);

/**
 * Return currently bookable timeslots with the (possible) current timeslot clipped
 * to the next half-hour
 */
export const parseBookableTimes = events => {
  let bookableTimes = events
    .filter(event => event.styleType === 'free')
    .map(event => ({ startsAt: moment(event.startsAt), endsAt: moment(event.endsAt) }));

  // Remove times completely in the past
  bookableTimes = bookableTimes.filter(time => moment().diff(time.endsAt, 's') <= 0);
  // Clip current free timeslot
  bookableTimes.forEach(time => {
    if (moment().diff(time.startsAt, 's') > 0) {
      time.startsAt = moment();
    }
  });

  return bookableTimes;
}

/**
 * Check that the given slot is within bookable times
 */
export const isBookableTime = (bookableTimes, start, end) => (
  bookableTimes.some(t => {
    return (
      start.isBetween(t.startsAt, t.endsAt, null, '[]') &&
      end.isBetween(t.startsAt, t.endsAt, null, '[]')
    );
  })
);

function substitute(base, booking, client) {
  return base == null ? ''
    : base.split('{date}').join(moment(booking.startsAt).format('dddd D.M. H:mm'))
        .split('{client}').join(client.name)
        .split('{address}').join(booking.address)
        .split('{phone}').join(booking.representative.user.phoneNumber)
        .split('{name}').join(`${booking.representative.user.firstName} ${booking.representative.user.lastName}`)
}

export const fillEmailTitleInfo = (booking, project, client) => {
  return substitute(project.emailTitleTemplate, booking, client)
}

export const isPasswordValid = password => {
  return password
    && password.length >= 8 
    && /[A-Z]/.test(password)
    && /[a-z]/.test(password)
    && /\d/.test(password)
    && /\W/.test(password)
}

export const fillEmailInfo = (booking, project, client) => {
  const base = booking.representative
      && booking.representative.emailTemplate
      && booking.representative.emailTemplate.length
    ? booking.representative.emailTemplate
    : (project ? project.emailTemplate : null)
  return substitute(base, booking, client)
}

export const fillSmsInfo = (booking, project, client) => {
  const base = booking.representative
      && booking.representative.smsTemplate
      && booking.representative.smsTemplate.length
    ? booking.representative.smsTemplate
    : (project ? project.smsTemplate : null)
  return substitute(base, booking, client)
}

/*
  TODO: An example function. Replace with a proper one.
*/
export const isPhoneNumberValid = number => {
  if ( !number ) return false
  if ( number.startsWith('+') ) {
    return /^\d+$/.test(number.slice(1)) && number.length === 13
  } else return /^\d+$/.test(number) && number.length === 10
}

export const isMfaCodeValid = code => 
  code && /^\d+$/.test(code) && code.length === 6

export const importRetry = async (importFn, retries = 5, interval = 1000) => {
  try {
    return await importFn();
  } catch (error) {
    if (retries) {
      await wait(interval);
      return importRetry(importFn, retries - 1, interval);
    } else {
      throw new Error(error);
    }
  }
}
  
export const wait = ms =>
  new Promise(resolve => setTimeout(resolve, ms))

// A temporary solution
export const CONTACT_BOOKING_IDS = [6, 7, 8, 17]
export const BUSINESS_NOSHOW_BOOKING_IDS = [5, 6, 7, 10]