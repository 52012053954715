import sortBy from 'lodash/sortBy';
import React, { Component } from 'react';
import {
  Button,
  Glyphicon,
  DropdownButton,
  MenuItem,
  Modal
} from 'react-bootstrap';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import * as contactActions from '../../redux/contacts/actions';
import * as businessActions from '../../redux/business-db/actions';
import PropTypes from 'prop-types';
import DecisionMakerInfoCard from '../decision-maker-info-ext/card'
import { BusyIndicator } from '../../components'
import { ContactInfoTags } from '../../components'
import { contactStatusToColor } from '../../utils';
import moment from 'moment';
import * as callListActions from '../../redux/call-lists/actions';
import { push } from 'react-router-redux';

export class ContactInfo extends Component {
  constructor(props) {
    super(props);

    if ( ['booker', 'teamLeader', 'admin'].includes(props.user.type) && !props.businessLines.length ) {
      this.props.getBusinessLines()
    }

    if ( !props.blank ) {
      this.state = {
        confirmCopyContact: false,
        confirmRemoveDecisionMaker: null,
        showDecisionMaker: !!props.includeDecisionMaker,
        showRelatedContacts: true,
        showAdditionalInformation: true,
        showContactInformation: true,
        showNotes: !(props.closeContactNotes === true),
        editNotes: true,
        notes: this.props.contact ? this.props.contact.notes : '',
        contactInfo: this.props.contact && this.props.contact.location
          ? {
            streetAddress: this.props.contact.location.streetAddress,
            postalCode: this.props.contact.location.postalCode,
            postalArea: this.props.contact.location.postalArea,
            phoneNumber: this.props.contact.phoneNumber,
            businessId: this.props.contact.businessId,
            email: this.props.contact.email
          } : {},
        contactData: this.getContactDataFromContact(this.props.contact)
      };
    } else {
      this.state = {
        showAdditionalInformation: true,
        showContactInformation: true,
        showNotes: !(props.closeContactNotes === true),
        editNotes: true,
        notes: '',
        contactInfo: {
            streetAddress: '',
            postalCode: '',
            postalArea: '',
            phoneNumber: '',
            businessId: '',
            email: this.props.emailField != null 
              ? '' : null
          },
        contactAdditionalInfo: this.getEmptyAdditionalInfo(this.props), 
        contactData: this.getContactDataFromContact(this.props.contact)
      };
    }
    this.setStateHandler(this.state, true)
  }

  getEmptyAdditionalInfo(props) {
    let obj = {};
    (props.template.fields || []).forEach(field => {
        const key = this.getKeyForTemplateField(field)
        obj[key] = ''
      })
    return {...obj, ...(props.defaultValues || {})}
  }

  getContactDataFromContact(contact) {
    let contactData = {}
    if ( contact && contact.contactData ) {
      contact.contactData.forEach(data => {
        contactData[data.id] = data.value
      })
    }
    return contactData
  }

  handleChangeContactData(event) {
    const contactData = this.props.contact.contactData.map(data => {
      if (data.id.toString() === event.target.id) {
        const newData = Object.assign({}, data);
        newData.value = event.target.value;
        return newData;
      } else {
        return data;
      }
    });
    this.props.setContactData(contactData);
  }

  _handleChangeContactData(id, e) {
    let contactData = this.state.contactData
    contactData[id] = e.target.value
    this.setStateHandler({contactData})
  }

  handleChangeContactInfo(param, e) {
    let contactInfo = this.state.contactInfo
    contactInfo[param] = e.target.value
    this.setStateHandler({contactInfo})
  }

  handleChangeAdditionalInfo(param, e) {
    let contactAdditionalInfo = this.state.contactAdditionalInfo
    contactAdditionalInfo[param] = e.target.value
    this.setStateHandler({contactAdditionalInfo})
  }

  handleKeyPress(param, e) {
    const charCode = (typeof e.which === "number") ? e.which : e.keyCode;
    if ( !this.props.blank && charCode === 13 ) {
      this.patchContactInfo(param)
      e.target.blur()
    }
  }

  handleBlur(param, location) {
    if ( !this.props.blank && this.hasChanged(param, location) ) {
      this.patchContactInfo(param)
    }
  }

  handleBusinessIdBlur() {
    if ( !this.props.blank && this.hasChanged('businessId', false) ) {
      this.patchContactInfo('businessId')
    }
  }

  handleEmailBlur() {
    if ( !this.props.blank && this.hasChanged('email', false) ) {
      this.patchContactInfo('email')
    }
  }

  handleContactDataKeyPress(id, e, title=null, value=null) {
    const charCode = (typeof e.which === "number") ? e.which : e.keyCode;
    if ( !this.props.blank && charCode === 13 ) {
      this.patchContactData(id, title, value)
      e.target.blur()
    }
  }

  handleContactDataBlur(id) {
    if ( !this.props.blank && this.hasContactDataChanged(id) ) {
      this.patchContactData(id)
    }
  }

  handleNotesBlur() {
    if ( !this.props.blank && this.hasChanged('notes', false) ) {
        this.props.setNotes(this.props.contact.id, this.state.notes)
    }
  }

  patchContactInfo(param){
    let patch = { contact: { id: this.props.contact.id } }
    if ( param === 'phoneNumber' || param === 'businessId' || param === 'email' ) {
      patch.contact[param] = this.state.contactInfo[param]
    } else {
      patch.location = { [param]: this.state.contactInfo[param] }
    }
    this.props.patchContact(patch)
  }

  patchContactData(id, title=null, value=null){
    let patch = { 
      contact: { id: this.props.contact.id },
      contactData: [{ 
        id: id, 
        value: value !== null 
          ? value 
          : this.state.contactData[id] 
      }]
    }
    if ( !id && title ) {
      patch.contactData[0].title = title
    }
    this.props.patchContact(patch)
  }

  UNSAFE_componentWillReceiveProps(nextProps){
    if ( this.props.blank && ( this.props.template?.id !== nextProps.template?.id ) ) {
      let contactInfo = {...this.state.contactInfo}
      contactInfo.email = nextProps.emailField != null ? '' : null
      this.setStateHandler({
        contactAdditionalInfo: this.getEmptyAdditionalInfo(nextProps),
        contactInfo: contactInfo
      })
    }
    if ( this.props.blank && nextProps.singleRequest && this.props.singleRequest !== nextProps.singleRequest ) {
      let businessLine = null
      if ( nextProps.singleRequest.businessLine && nextProps.singleRequest.businessLineId ) {
        businessLine = `${nextProps.singleRequest.businessLineId} ${nextProps.singleRequest.businessLine}`
      }
      this.setStateHandler({
        contactInfo: {
          streetAddress: nextProps.singleRequest.postalAddressAddress,
          postalCode: nextProps.singleRequest.postalAddressPostalCode,
          postalArea: nextProps.singleRequest.postalAddressPostalArea,
          phoneNumber: nextProps.singleRequest.phoneNumber,
          email: nextProps.singleRequest.email,
          businessId: nextProps.singleRequest.businessId
        },
        contactAdditionalInfo: {
          Yritysmuoto: nextProps.singleRequest.form,
          Toimiala: businessLine,
          Aktiivinen: nextProps.singleRequest.active 
            ? 'Kyllä' : 'Ei'
        }
      })
    }
    if ( this.props.contact !== nextProps.contact ) {
      this.setStateHandler({
        contactInfo: nextProps.contact && nextProps.contact.location 
          ? {
            streetAddress: nextProps.contact.location.streetAddress,
            postalCode: nextProps.contact.location.postalCode,
            postalArea: nextProps.contact.location.postalArea,
            phoneNumber: nextProps.contact.phoneNumber,
            businessId: nextProps.contact.businessId,
            email: nextProps.contact.email
          } : {},
        notes: nextProps.contact.notes || '',
        contactData: this.getContactDataFromContact(nextProps.contact)
      })
    }
  }

  hasChanged(param, location, info=true) {
    const root = location ? this.props.contact.location : this.props.contact
    return root && (info ? this.state.contactInfo : this.state)[param] !== root[param]
  }

  hasContactDataChanged(id) {
    const contactData = this.props.contact.contactData?.find(data => data.id === id)
    return !contactData || contactData.value !== this.state.contactData[id]
  }

  setStateHandler(state, bypassLocal=false) {
    if ( !bypassLocal ) this.setState(state)
    if ( this.props.parentStateHandler ) {
      this.props.parentStateHandler(state);
    }
  }

  getInputStyle(param, location){
    return {
      fontWeight: !this.props.blank 
        && this.hasChanged(param, location)
          ? 'bold' : 'normal',
      borderBottom: this.props.blank
        ? '1px dashed #ccc' : 0
    }
  }

  getContactDataInputStyle(id){
    return {
      fontWeight: this.hasContactDataChanged(id)
        ? 'bold' : 'normal'
    }
  }

  getNotesStyle(){
    return { 
      fontWeight: !this.props.blank && this.hasChanged('notes', false, false)
        ? 'bold' : 'normal'
    }
  }

  getKeyForTemplateField(field){
    return field.displayName || field.aliases[0]
  }

  confirmRemoveDecisionMaker() {
    if ( this.state.confirmRemoveDecisionMaker.id < 0 ) {
      this.props.removeUnsavedDecisionMaker(this.state.confirmRemoveDecisionMaker)
    } else {
      this.props.removeDecisionMaker(this.state.confirmRemoveDecisionMaker.id)
    }
    this.setState({confirmRemoveDecisionMaker: null})
  }

  confirmCopyContact() {
    this.props.copyContact(this.props.contact.id)
    this.setState({confirmCopyContact: false})
  }

  hasInvalidValue(contactInfo, value) {
    if ( this.props.blank || this.props.readOnly ) {
      return false
    }
    if ( contactInfo.required ) {
      if ( ['select', 'text', 'tag', 'number'].includes(contactInfo.type) ) {
        return !value
      }
    }
    return false
  }

  goToContact(contact) {
    if ( this.props.contact?.project.businessPerDecisionMaker ) {
      const cuiId = 2
      const path = `/ui/${cuiId}/call/${contact.id}`
      if ( this.props.path !== path ) {
        this.props.resetCurrentCallType()
        this.props.redirect(path);
      }
    }
  }

  render() {

    const textAreaStyle = {
      border: "solid 1px #d6d6d6",
//      border: 0,
//      background: "rgba(0,0,0,.02)",
      borderRadius: "4px",
      height: "75px",
      padding: "5px",
      resize: "none",
      width: "100%",
      fontSize: "13px"
    }

    return (
      <div className="contact-info" style={this.props.style}>
        {this.props.includeDecisionMaker && 
          <div className="heading" style={{ paddingBottom: 0, display: "flex", alignItems: "center" }}>
            {this.props.t('decisionMakerInformation')}
            <Button
              style={{ marginBottom: 0 }}
              bsSize="small"
              onClick={() =>
                this.setStateHandler({
                  showDecisionMaker: !this.state.showDecisionMaker
                })
              }
            >
              {this.state.showDecisionMaker ? (
                <Glyphicon glyph="chevron-up" />
              ) : (
                <Glyphicon glyph="chevron-down" />
              )}
            </Button>
            <Button onClick={() => {this.props.onClick()}}
                disabled={
                  this.props.maxDecisionMakers && 
                  this.props.people.length >= this.props.maxDecisionMakers
                }
                style={{
                    alignSelf: 'center',
                    width: 25, 
                    height: 25, 
                    padding: 0,
                    backgroundColor: 'rgb(165, 24, 144)',
                    borderColor: 'rgb(165, 24, 144)',
                    outlineColor: 'rgb(165, 24, 144)',
                    fontSize: 11,
                    margin: 0,
                    marginLeft: 5
                }} 
                bsStyle='primary'>
                <Glyphicon glyph="plus"></Glyphicon>
            </Button>
          </div>
        }
        {this.state.showDecisionMaker && ( (this.props.people || []).length || this.props.decisionMakersLoading ) && 
          <div className="info-row wrap decision-maker-info-ext" style={{ borderBottom: 0, textAlign: 'left', paddingTop: 0 }}>
            { this.props.decisionMakersLoading && <div style={{ width: '100%' }}><BusyIndicator /></div> }
            {
              (this.props.people || []).map((person, i) => 
                <DecisionMakerInfoCard 
                  people={this.props.people}
                  tags={this.props.tags}
                  additionalRequiredFields={
                    this.props.tags.find(tag => tag.id === person.id)
                      ? ( [2, 3].includes(this.props.contactMainStatusId) ? ['tag'] : ['tag', 'email', 'tag2'] )
                      : []
                    }
                  key={person.id}
                  person={person} 
                  onDecisionMakerStateEvent={this.props.onDecisionMakerStateEvent}
                  onClick={() => {
                    this.setState({confirmRemoveDecisionMaker: person})
                  }}
                  onCheck={person => {
                    if ( person.id < 0 ) {
                      this.props.removeUnsavedDecisionMaker(person, () => {
                        this.props.postDecisionMaker({
                          ...person, 
                          businessId: this.props.contact.businessId,
                          projectId: person.unknown 
                            ? this.props.contact.projectId : null,
                          contactId: this.props.contact.project.businessPerDecisionMaker
                             ? this.props.contact.id : null
                        })
                      })
                    } else {
                      this.props.patchDecisionMaker({
                        ...person,
                        projectId: person.unknown 
                          ? this.props.contact.projectId 
                          : null
                      })
                    }
                  }}
                  icon="minus"
                  checkable
                />
                )
            }
          </div>
        }
        { this.props.includeRelatedContacts &&
          <div className="heading" style={{ paddingBottom: 0, display: "flex", alignItems: "center" }}>
            {this.props.t('relatedContacts')}
            <Button
              style={{ marginBottom: 0 }}
              bsSize="small"
              onClick={() => {
                this.setStateHandler({
                  showRelatedContacts: !this.state.showRelatedContacts
                })
              }}
            >
              {this.state.showRelatedContacts ? (
                <Glyphicon glyph="chevron-up" />
              ) : (
                <Glyphicon glyph="chevron-down" />
              )}
            </Button>
            <Button onClick={() => {
              this.setState({confirmCopyContact: true})
            }}
              style={{
                  alignSelf: 'center',
                  width: 98, 
                  height: 25, 
                  padding: "0 7px 0 7px",
                  backgroundColor: 'rgb(165, 24, 144)',
                  borderColor: 'rgb(165, 24, 144)',
                  outlineColor: 'rgb(165, 24, 144)',
                  fontSize: 11,
                  margin: 0,
                  marginLeft: 5
              }} 
              bsStyle='primary'>
              <Glyphicon glyph="plus"></Glyphicon>
              <span style={{ marginLeft: 4, fontWeight: 600 }}>
                { this.props.t("copyContact") }
              </span>
            </Button>
          </div>
        }


        { this.props.includeRelatedContacts && 
          this.state.showRelatedContacts && 
          <div className="info-row" style={{ 
            borderBottom: 0, 
            textAlign: 'left', 
            paddingTop: 0, 
            flexFlow: 'wrap',
          }}>
            {(this.props.contact?.relatedContacts || []).map(contact => {
              const statusName = contact?.status?.parentStatus?.name || contact?.status?.name
              const decisionMakerName = contact?.firstDecisionMaker?.name
              const timestamp = contact?.bookerActions[0]?.timestamp
              const timestampStr = moment(timestamp).format('DD.MM.YYYY HH:mm')
              return <div key={contact.id} className="info-row-column" style={{ 
                display: "flex", 
                marginTop: 10,
                marginRight: 10, 
                padding: 0,
                width: "fit-content" 
              }}>
                <div onClick={() => this.goToContact(contact)} 
                style={{ 
                  display: "flex", 
                  boxShadow: "rgba(0, 0, 0, 0.2) 1px 1px 3px", 
                  borderRadius: "5px",
                  cursor: "pointer"
                }}>
                  <div style={{ 
                    display: "flex", 
                    flex: 0, 
                    borderLeft: "5px solid", 
                    borderColor: contactStatusToColor(contact?.status)
                  }}>
                    <div>
                      <Button
                        style={{ 
                          paddingLeft: "8px",
                          paddingRight: "12px",
                          fontSize: "11px", 
                          border: "none", 
                          backgroundColor: "unset", 
                          boxShadow: "none",
                          color: 'rgb(165, 24, 144)'
                        }}
                        bsSize="small"
                        onClick={e => {
                          this.goToContact(contact)
                          e.stopPropagation()
                        }}
                      >
                        <Glyphicon glyph="chevron-right" />
                      </Button>
                    </div>
                  </div>
                  <div style={{ 
                    display: "flex", 
                    justifyContent: "center", 
                    flexFlow: "column", 
                    padding: "12px 18px 12px 2px" 
                  }}>
                    <div>{ contact?.name }{ decisionMakerName ? `, ${decisionMakerName }` : '' }</div>
                    <div>{ statusName }{ timestamp ? ` (${timestampStr})` : '' }</div>
                  </div>
                </div>
              </div>
            })}
          </div>
        }

        <div className="heading">
          {this.props.t('contactInformation')}
          <Button
            bsSize="small"
            onClick={() =>
              this.setStateHandler({
                showContactInformation: !this.state.showContactInformation
              })
            }
          >
            {this.state.showContactInformation ? (
              <Glyphicon glyph="chevron-up" />
            ) : (
              <Glyphicon glyph="chevron-down" />
            )}
          </Button>
        </div>
        {this.state.showContactInformation && (
          <div className="info-row wrap" style={{ borderBottom: 0 }}>
            <div className="info-row-column">
              <span>{this.props.t('streetAddress')}</span>
              <br />
              <input
                style={ this.getInputStyle('streetAddress', true) }
                value={this.state.contactInfo.streetAddress || ''}
                onKeyPress={ e => {this.handleKeyPress('streetAddress', e)}}
                onChange={ e => {this.handleChangeContactInfo('streetAddress', e)} }
                onBlur={ e => {this.handleBlur('streetAddress', true)}}
                readOnly={ this.props.readOnly }
                type="text"
              />
            </div>
            <div className="info-row-column">
              <span>{this.props.t('postalCode')}</span>
              <br />
              <input
                style={ this.getInputStyle('postalCode', true) }
                value={this.state.contactInfo.postalCode || ''}
                onKeyPress={ e => {this.handleKeyPress('postalCode', e)}}
                onChange={ e => {this.handleChangeContactInfo('postalCode', e)} }
                onBlur={ e => {this.handleBlur('postalCode', true)}}
                readOnly={ this.props.readOnly }
                type="text"
              />
            </div>
            <div className="info-row-column">
              <span>{this.props.t('postalArea')}</span>
              <br />
              <input
                style={ this.getInputStyle('postalArea', true) }
                value={this.state.contactInfo.postalArea || ''}
                onKeyPress={ e => {this.handleKeyPress('postalArea', e)}}
                onChange={ e => {this.handleChangeContactInfo('postalArea', e)} }
                onBlur={ e => {this.handleBlur('postalArea', true)}}
                readOnly={ this.props.readOnly }
                type="text"
              />
            </div>
            <div className="info-row-column">
              <span>{this.props.t('phone')}</span>
              <br />
              <input
                style={ this.getInputStyle('phoneNumber', false) }
                onKeyPress={ e => {this.handleKeyPress('phoneNumber', e)}}
                onChange={ e => {this.handleChangeContactInfo('phoneNumber', e)} }
                onBlur={ e => {this.handleBlur('phoneNumber', false)}}
                value={this.state.contactInfo.phoneNumber || ''}
                readOnly={ this.props.readOnly }
                type="text"
              />
            </div>
            <div className="info-row-column">
              <span>{this.props.t('email')}</span>
              <br />
              <input
                readOnly={ this.props.readOnly }
                style={ this.getInputStyle('email', false) }
                onKeyPress={ e => {this.handleKeyPress('email', e)}}
                onChange={ e => {this.handleChangeContactInfo('email', e)} }
                onBlur={ e => {this.handleEmailBlur('email', false)}}
                value={this.state.contactInfo.email || ''}
                id={ 'email' }
                type="text"
              />
            </div>
          </div>
        )}

        <div className="heading">
          {this.props.t('additionalInformation')}
          <Button
            bsSize="small"
            onClick={() =>
              this.setStateHandler({
                showAdditionalInformation: !this.state.showAdditionalInformation
              })
            }
          >
            {this.state.showAdditionalInformation ? (
              <Glyphicon glyph="chevron-up" />
            ) : (
              <Glyphicon glyph="chevron-down" />
            )}
          </Button>
        </div>

        {this.state.showAdditionalInformation && !this.props.blank && (
          <div className="info-row wrap" style={{ borderBottom:  0 }}>
            {sortBy(this.props.contact.contactData, 'orderNr')
              .filter(contactData => 
                !(this.props.contact.projectAdditionalContactInfo &&
                this.props.contact.projectAdditionalContactInfo.find(
                  contactInfo => contactInfo.title === contactData.title
                ))
              ).map(
              dataField => {
                return (
                  <div className="info-row-column" key={dataField.id}>
                    <span>{dataField.title}</span>
                    <br />
                    <input
                      style={this.getContactDataInputStyle(dataField.id)}
                      id={dataField.id}
                      readOnly={ this.props.readOnly }
                      type="text"
                      onChange={ e => {this._handleChangeContactData(dataField.id, e)} }
                      onBlur={ e => { this.handleContactDataBlur(dataField.id)}}
                      onKeyPress={ e => {this.handleContactDataKeyPress(dataField.id, e)}}
                      value={this.state.contactData[dataField.id] || ''}
                    />
                  </div>
                );
              }
            )}
            {
              this.props.contact.projectAdditionalContactInfo &&
              sortBy(this.props.contact.projectAdditionalContactInfo, 'orderNr').filter(
                contactInfo => contactInfo.type !== 'tag'
              ).map(contactInfo => {
                const cdObj = ( 
                    this.props.contact.contactData.find(
                      contactData => contactData.title === contactInfo.title
                    ) || {} 
                  )
                const { value, id } = cdObj

                if ( this.props.contact && 
                  this.props.contact.project && 
                  this.props.contact.project.customUserInterfaceId === 2 && 
                  contactInfo.title === 'Aktiivinen' && 
                  this.props.user.type === 'booker' ) {
                  return null
                }

                if ( this.props.readOnly && !value ) return null
                if ( contactInfo.type === 'select' ) {
                  const _id = id || contactInfo.title
                  return <div className={
                    'info-row-column' + 
                    (contactInfo.title === 'Toimiala' 
                      ? ' business-line-column' 
                      : ''
                    )} key={contactInfo.id}>
                      <span
                        style={
                          this.hasInvalidValue(contactInfo, value)
                            ? { color: 'red' } : {}
                        }
                      >{contactInfo.title}</span>
                      <br />
                      { this.props.readOnly &&
                        <input
                          readOnly={ true }
                          type="text"
                          value={value}
                        />
                      }
                      { !this.props.readOnly &&
                        <DropdownButton
                          style={{
                            width: "100%",
                            outline: this.hasInvalidValue(contactInfo, value)
                              ? '1px solid red' 
                              : null
                          }}
                          id="booking-booker"
                          disabled={ 
                            this.props.user.type === 'booker' && 
                            contactInfo.title === 'Yritysmuoto' 
                          }
                          title={ value || 'Valitse...' }
                        >
                        {(
                          contactInfo.title === 'Toimiala' 
                            ? this.props.businessLines 
                            : contactInfo.options.split('\n')
                          ).map((option, i) => {
                          return (
                            <MenuItem
                              active={option === value}
                              eventKey={option}
                              key={i}
                              onSelect={eventKey => {
                                let contactData = this.state.contactData
                                contactData[id] = eventKey
                                this.setState({contactData}, () => {
                                  this.patchContactData(id, contactInfo.title)
                                })
                              }}
                            >
                              { option }
                            </MenuItem>
                          );
                        })}
                        </DropdownButton>
                      }
                  </div>
                } else if ( ['text', 'number'].includes(contactInfo.type) ) {
                  const _id = id || contactInfo.title
                  const value = this.state.contactData[_id] || ''
                  const convertToNumber = value => {
                    if ( !value ) return value
                    const s = value
                      .replaceAll(' ', '')
                      .replaceAll(',', '.')
                    const f = parseFloat(s).toFixed(2)
                    if ( isNaN(f) || !f ) return ''
                    const r = f.toString()
                      .replaceAll('.', ',')
                    if ( r.length ) {
                      const [a, b] = r.split(',')
                      const c = a.split('')
                        .reverse()
                        .join('')
                        .replace(/.{3}/g, '$& ')
                        .split('')
                        .reverse()
                        .join('')
                        .trim()
                      return `${c},${b}`
                    } else return r
                  }
                  return (
                    <div className="info-row-column" key={contactInfo.id}>
                      <span style={
                          this.hasInvalidValue(contactInfo, value)
                            ? { color: 'red' } : {}
                        }>{contactInfo.title}</span>
                      <br />
                      <input
                        style={this.getContactDataInputStyle(_id)}
                        id={contactInfo.id}
                        readOnly={ this.props.readOnly }
                        type="text"
                        onChange={ e => {
                          this._handleChangeContactData(_id, e)} 
                        }
                        onBlur={ e => { 
                          if ( !this.props.readOnly ) {
                            this.patchContactData(
                              id, 
                              contactInfo.title, 
                              contactInfo.type === 'number' 
                                ? convertToNumber(value) 
                                : value
                            )
                          }
                        }}
                        onKeyPress={ e => {
                          this.handleContactDataKeyPress(
                            id, 
                            e,
                            contactInfo.title, 
                            contactInfo.type === 'number' 
                              ? convertToNumber(value) 
                              : value
                          )
                        }}
                        value={value}
                      />
                    </div>
                  );
                }
              })
            }
            { this.state.contactInfo.businessId != null &&
              <div className="info-row-column">
                <span>Y-tunnus</span>
                <br />
                <input
                  readOnly={ this.props.readOnly }
                  style={ this.getInputStyle('businessId', false) }
                  onKeyPress={ e => {this.handleKeyPress('businessId', e)}}
                  onChange={ e => {this.handleChangeContactInfo('businessId', e)} }
                  onBlur={ e => {this.handleBusinessIdBlur('businessId', false)}}
                  value={this.state.contactInfo.businessId}                
                  id={ 'businessId' }
                  type="text"
                />
              </div>
            }
            {
              this.props.contact.projectAdditionalContactInfo &&
              sortBy(this.props.contact.projectAdditionalContactInfo, 'orderNr').filter(
                contactInfo => contactInfo.type === 'tag'
              ).map((contactInfo, i) => {
                const cdObj = ( 
                  this.props.contact.contactData.find(
                    contactData => contactData.title === contactInfo.title
                  ) || {} 
                )
                const { value, id } = cdObj
                const _id = id || contactInfo.title
                const values = value?.split('§')
                if ( this.props.readOnly && !value ) return null

                return (
                  <div className="info-row-column" style={{ 
                    width: '100%', 
                    zIndex: (this.props.contact.projectAdditionalContactInfo.length - i) * 10
                  }} 
                  key={contactInfo.id}>
                    <span style={
                          this.hasInvalidValue(contactInfo, value)
                            ? { color: 'red' } : {}
                      }>{contactInfo.title}</span>
                    <br />
                    <ContactInfoTags 
                      readOnly={this.props.readOnly}
                      options={contactInfo.options.split('\n')} 
                      values={values && values[0].length ? values : []}
                      onChange={values => {
                        this.patchContactData(
                          id, 
                          contactInfo.title, 
                          values.join('§')
                        )
                      }}
                    />
                  </div>
                )
              })
            }
          </div>
        )}
        {this.state.showAdditionalInformation && this.props.blank && (
          <div className="info-row wrap" style={{ borderBottom:  0 }}>
            {this.props.template.fields.filter(field => {
              const key = this.getKeyForTemplateField(field)
              if ( this.props.project && this.props.project.additionalContactInfo ) {
                return !this.props.project.additionalContactInfo.find(
                  contactInfo => contactInfo.title === key
                )
              } else return true
            }).map(
              (field, i) => {
                const key = this.getKeyForTemplateField(field)
                return <div className="info-row-column" key={ i }>
                  <span>{ key }</span>
                  <br />
                  <input
                    style={this.getInputStyle(null,null)}
                    id={ i }
                    onChange={ e => {this.handleChangeAdditionalInfo(key, e)} }
                    type="text"
                  />
                </div>
              }
            )}
            {
              this.props.project && this.props.project.additionalContactInfo &&
              this.props.project.additionalContactInfo.filter(contactInfo => contactInfo.type !== 'tag')
              .map(contactInfo => {
                const value = this.state.contactAdditionalInfo[contactInfo.title]
                if ( contactInfo.type === 'select' ) {
                  return <div className={
                    'info-row-column' + 
                    (contactInfo.title === 'Toimiala' 
                      ? ' business-line-column' 
                      : ''
                    )} key={contactInfo.id}>
                      <span>{contactInfo.title}</span>
                      <br />
                        <DropdownButton
                          style={{ width: "100%"}}
                          id="booking-booker"
                          title={ value || 'Valitse...' }
                        >
                        {(
                          contactInfo.title === 'Toimiala' 
                            ? this.props.businessLines 
                            : contactInfo.options.split('\n')
                          ).map((option, i) => {
                          return (
                            <MenuItem
                              active={option === value}
                              eventKey={option}
                              key={i}
                              onSelect={eventKey => {
                                this.handleChangeAdditionalInfo(
                                  contactInfo.title, 
                                  {target:{value: eventKey}}
                                )
                              }}
                            >
                              { option }
                            </MenuItem>
                          );
                        })}
                        </DropdownButton>
                  </div>
                } else if ( ['text', 'number'].includes(contactInfo.type) ) {
                  return <div className="info-row-column" key={ contactInfo.id }>
                    <span>{ contactInfo.title }</span>
                    <br />
                    <input
                      style={this.getInputStyle(null,null)}
                      id={ contactInfo.id }
                      onChange={ e => {this.handleChangeAdditionalInfo(contactInfo.title, e)} }
                      type="text"
                    />
                  </div>
                }
              })
            }
            { ( this.props.businessIdField || this.props.includeBusinessIdField ) &&
              <div className="info-row-column">
                <span>Y-tunnus</span>
                <br />
                <div style={{ display: 'flex' }}>
                  <input
                    disabled={this.props.project?.customUserInterfaceId === 2 && this.props.singleRequest?.businessId}
                    onChange={ e => {this.handleChangeContactInfo('businessId', e)} }
                    value={this.state.contactInfo.businessId}                
                    style={this.getInputStyle(null,null)}
                    id={ 'businessId' }
                    type="text"
                  />
                </div>
              </div>
            }
            {
              this.props.project && this.props.project.additionalContactInfo &&
              this.props.project.additionalContactInfo.filter(
                contactInfo => contactInfo.type === 'tag'
              ).map((contactInfo, i) => {
                const values = this.state.contactAdditionalInfo[contactInfo.title]?.split('§')
                return (
                  <div className="info-row-column" style={{ 
                    width: '100%', 
                    zIndex: (this.props.project.additionalContactInfo.length - i) * 10
                  }} key={ contactInfo.id }>
                    <span>{ contactInfo.title }</span>
                    <br />
                    <ContactInfoTags 
                      options={contactInfo.options.split('\n')} 
                      values={values && values[0].length ? values : []}
                      onChange={values => {
                        this.handleChangeAdditionalInfo(
                          contactInfo.title, 
                          {target:{value: values.join('§')}}
                        )
                      }}
                    />
                  </div>
                )
              })
            }
          </div>
        )}
        { this.props.user.type !== 'representative' && this.props.user.type !== 'clientAdmin' &&
          <React.Fragment>
          <div className="heading">
          {this.props.t('contactNotes')}
          <Button
            bsSize="small"
            onClick={() =>
              this.setStateHandler({
                showNotes: !this.state.showNotes
              })
            }
          >
            {this.state.showNotes ? (
              <Glyphicon glyph="chevron-up" />
            ) : (
              <Glyphicon glyph="chevron-down" />
            )}
          </Button>
        </div>

        {this.state.showNotes && (
          <div style={{ paddingTop: 0 }} className="info-row wrap">
            { this.state.editNotes &&
              <textarea
                readOnly={ this.props.notesReadOnly }
                onBlur={ e => {this.handleNotesBlur()}}
                style={{
                  ...textAreaStyle,
                  ...this.getNotesStyle()
                }}
                value={this.state.notes || ''}
                type="text"
                onChange={e => {
                  this.setStateHandler({notes: e.target.value})
                }}
              />
            }
            { ( !this.state.editNotes && this.state.notes && this.state.notes.length) &&
              <span style={{ padding: '0 10px 10px 10px' }}>{ this.state.notes }</span>
            }
          </div>
        )}
        { !this.state.showNotes &&
          <div style={{ padding: 0 }} className="info-row wrap"></div>
        }
        </React.Fragment>
        }
        <Modal onHide={() => this.setState({confirmRemoveDecisionMaker: null})} 
          show={this.state.confirmRemoveDecisionMaker != null}>
          <Modal.Header closeButton={false}>
            <Modal.Title>{this.props.t('confirmRemoveDecisionMaker')}</Modal.Title>
          </Modal.Header>
          <Modal.Footer>
            <Button onClick={() => this.confirmRemoveDecisionMaker()}>
              {this.props.t('yes')}
            </Button>
            <Button onClick={() => this.setState({confirmRemoveDecisionMaker: null})}>
              {this.props.t('no')}
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal onHide={() => this.setState({confirmCopyContact: false})} 
          show={this.state.confirmCopyContact}>
          <Modal.Header closeButton={false}>
            <Modal.Title>{this.props.t('confirmCopyContact')}</Modal.Title>
          </Modal.Header>
          <Modal.Footer>
            <Button onClick={() => this.confirmCopyContact()}>
              {this.props.t('yes')}
            </Button>
            <Button onClick={() => this.setState({confirmCopyContact: false})}>
              {this.props.t('no')}
            </Button>
          </Modal.Footer>
        </Modal>

      </div>
    );
  }
}

ContactInfo.propTypes = {
  readOnly: PropTypes.bool
};

const handleTemplateFields = template => 
  template.fields != null && template.fields.length
  ? ('columnNr' in template.fields[0]
    ? template.fields.sort(
        (a, b) => ( a.columnNr > b.columnNr) ? 1 : -1
      )
    : [
        ...template.fields.filter(field => !field.optional),
        ...template.fields.filter(field => field.optional)
      ]
    )
  : []

const mapStateToProps = state => ({
  singleRequestState: state.businessDb.requestState.singleRequest,
  singleRequest: state.businessDb.singleRequest,
  businessLines: state.businessDb.businessLines,
  contact: state.contacts.contact,
  contactStatuses: state.contacts.contactStatuses,
  user: state.user,
  businessIdField: (state.templates.template.fields || []).find(
    field => field.knownType === 'businessId' && !field.optional
  ),
  emailField: 'email' /*(state.templates.template.fields || []).find(
    field => field.knownType === 'email' && !field.optional
  )*/,
  template: {
    ...state.templates.template,
    fields: handleTemplateFields(state.templates.template)
      .filter(field => field.knownType == null)
  }});

const mapDispatchToProps = dispatch => ({
  copyContact: contactId => {
    dispatch(contactActions.copyContact({contactId}))
  },
  removeDecisionMaker: decisionMakerId => {
    dispatch(contactActions.removeDecisionMaker({decisionMakerId}))
  },
  postDecisionMaker: decisionMaker => {
    dispatch(contactActions.postDecisionMaker({decisionMaker}))
  },
  patchDecisionMaker: decisionMaker => {
    dispatch(contactActions.patchDecisionMaker({decisionMaker}))
  },
  setNotes: (contactId, notes) => {
    dispatch(
      contactActions.setContactNotes({
        contactId,
        notes
      })
    );
  },
  patchContact: contact => {
    dispatch(
      contactActions.patchContact(contact)
    );
  },
  getBusinessLines: () => {
    dispatch(businessActions.getBusinessLines())
  },
  getBusiness: businessId => {
    dispatch(businessActions.getSingleRequest(businessId))
  },
  getContactCard: contactId => {
    dispatch(contactActions.getContactCard({contactId}))
  },
  resetCurrentCallType: () => {
    dispatch(callListActions.setCurrentCallType(null));
  },
  redirect: url => {
    dispatch(push(url));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(
  translate('translations')(ContactInfo)
);
