import React, { Component } from 'react';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import moment from 'moment';
import { Button, Glyphicon } from 'react-bootstrap';
import { push } from 'react-router-redux';
import { AudioPlayer } from '../audio-player';

import * as callActions from '../../redux/call/actions';
import * as callListActions from '../../redux/call-lists/actions';
import * as contactActions from '../../redux/contacts/actions';

export class CallHistory extends Component {

  constructor(props) {
    super(props)
    props.getCalls(props.project.id)
    this.state = {
      scrollTop: 0,
      search: ''
    }
  }

  paneDidMount = (node) => {    
    if(node) {
      node.addEventListener("scroll", e => {
        const scrollTop = e.target.scrollTop
        this.setState({scrollTop})
        this.handleScroll.bind(this)()
      })
    }
  }

  handleScroll = e => {    
    Object.keys(this.state)
      .filter(key => key.startsWith('call'))
      .forEach(key => {
        if ( this.state[key] ) {
            this.setState({[key]: false})        
        }
      })
  }

  handleOpenSearch = search => {
    const w = 400
    const h = 480
    const y = window.top.outerHeight / 2 + window.top.screenY - ( h / 2);
    const x = window.top.outerWidth / 2 + window.top.screenX - ( w / 2);
    const newWindow = window.open(`/call-history-popup/search=${search}`, 'call-history', 
      `width=${w},height=${h},left=${x},top=${y},directories=0,titlebar=0,toolbar=0,menubar=0,location=0`
    )
    newWindow.focus()
  }

  render() {
    return (
    <div style={{ display: 'flex', flexFlow: 'column', height: 0, minHeight: 320, flex: 'auto', paddingTop: 15, textAlign: 'left' }}>
        <span style={{ paddingLeft: 10, fontWeight: 'bold', marginBottom: 4 }}>{ this.props.t('call.callHistory') }</span>
        <div style={{ display: 'flex' }}>
          <input 
            type="text" 
            value={ this.state.search } 
            onChange={ e => {this.setState({search: e.target.value})}}
            placeholder="Etsi numerolla..." 
            onKeyDown={e => {
              if (e.keyCode === 13 && this.state.search.length ) {
                this.handleOpenSearch(this.state.search)
              }
              e.stopPropagation()
            }}  
            style={{ flex: 1, paddingLeft: 10, paddingRight: 10, border: 0, borderTop: '1px solid #ccc', outline: 'none' }}>
          </input>
          <Button disabled={ !this.state.search.length } onClick={e => { this.handleOpenSearch(this.state.search) }}>
            <Glyphicon glyph="search" />
          </Button>
        </div>
        <div style={{ padding: 0, overflowY: 'auto', borderTop: 'solid 1px #d6d6d6' }} ref={this.paneDidMount}>
          {(this.props.calls || []).map((call, i) => {
            const duration = call.telavoxCall ? call.telavoxCall.duration : ( call.answered ? call.duration : 0 )
            const callId = call.id || ( ( call.telavoxCall.recordFile ? '_' : '' ) + call.telavoxCallId )
            return (
              <div key={i}
                  style={{ 
                  display: 'flex', 
                  alignItems: 'center', 
                  textAlign: 'left', 
                  padding: 10, 
                  paddingTop: 5, 
                  paddingBottom: 5, 
                  borderBottom: 'solid 1px #d6d6d6'
                }}>
                <div style={{ flex: 1 }}>
                  { !call.contact && <div>{ call.phoneNumber }</div> }
                  { 
                    call.contact && 
                    <div>
                      <a href={this.props.project.customUserInterfaceId === 2 
                        ? `/ui/2/call/${call.contact.id}` 
                        : `/contacts/${call.contact.id}`} 
                        onClick={e => {
                          e.preventDefault(); 
                          this.props.resetCurrentCallType()
                          this.props.redirect(
                            this.props.project.customUserInterfaceId === 2 
                              ? `/ui/2/call/${call.contact.id}` 
                              : `/contacts/${call.contact.id}`
                          )
                        }}
                      >
                        { call.contact.name }
                      </a>
                    </div> 
                  }
                  <div style={{ fontSize: 13}}>{ moment(call.startsAt).format('DD.MM.YYYY HH:mm') } <span style={{ opacity: duration ? 1 : .6 }}>({ moment.utc(moment.duration(duration, "seconds").asMilliseconds()).format("mm:ss") })</span></div>
                </div>
                {
                  ( call.telavoxCall && (call.telavoxCall.recordFile || call.telavoxCall.recordingId) ) &&
                  <div>
                    { 
                      this.state['call' + callId] &&
                      <AudioPlayer parentScrollTop={this.state.scrollTop}
                        token={this.props.user.token} 
                        callId={callId} closeFn={callId => {
                        const key = 'call' + callId
                        this.setState({[key]: false})
                      }}/>
                    }
                    <Button onClick={e => {
                        const key = 'call' + callId
                        Object.keys(this.state)
                          .filter(_key => _key !== key && _key.startsWith('call'))
                          .forEach(key => {
                            if ( this.state[key] ) {
                                this.setState({[key]: false})        
                            }
                          })                    
                        this.setState({[key]: true})
                        e.preventDefault()
                    }}>
                        <Glyphicon glyph="play" />
                    </Button>
                  </div>                        
                }
              </div>
            )
          })}
        </div>
    </div>
    );
  }
}

const mapStateToProps = state => ({
    calls: state.call.calls || [],
    user: state.user,
    project: state.call.selectedProject
});
const mapDispatchToProps = dispatch => ({
    resetCurrentCallType: () => {
      dispatch(callListActions.setCurrentCallType(null));
    },
    redirect: url => {
        dispatch(push(url));
    },
    getRecording: (callId, token) => {
        dispatch(callActions.getRecording({callId, token}));
      },
    getCalls: projectId => {
        dispatch(callActions.getCalls({projectId, limit: 10, onlyOwn: true}));
    },
    getContactCard: contactId => {
      dispatch(contactActions.getContactCard({contactId}))
    },  
});

export default connect(mapStateToProps, mapDispatchToProps)(
  translate('translations')(CallHistory)
);
