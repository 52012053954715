import {
    isBusinessBookingMadeOrCancelled, 
    getSelectedStatusName, 
    isBookingInBusinessUi 
} from "./utils"
import { DropdownButton, MenuItem } from "react-bootstrap"

export const ContactStatusDropdown = ({
    props,
    onToggle,
    open,
    contactStatuses,
    disabled,
    isLoading,
    status,
    setStatus,
    setUserModified
}) => {

    const handleSelectStatus = statusId => {
        const status = contactStatuses.find(
          status => status.id === statusId
        )
        if (status.subStatuses.length === 0) {
          setStatus(status)
        } else {
          setStatus(status.subStatuses[0])
        }
        setUserModified(true)
    }
    
    const getDropdownTitle = () => {
      const title = isBookingInBusinessUi(props) && mainStatusId === 10 
        ? "Buukattu" 
        : getSelectedStatusName(contactStatuses, mainStatusId, props) || props.status?.name || ''
      return <>
        { title } 
        { isLoading && 
          <div style={{ position: 'absolute', right: 8, top: 19}}>
            <div className="spinner-container" style={{ lineHeight: '26px' }}>
              <span className="spinner">
                <span className="bottom" style={{
                  borderTopColor: '#ccc',
                  borderWidth: 3
                }}></span>
              </span>
              <span className="loading"></span>
            </div>
          </div>
        }
      </>
    }

    const isDisabled = disabled || 
      isLoading || 
      ( props.disableStatusIfBooked && 
        props.status?.superType === 'booking' ) || 
      isBusinessBookingMadeOrCancelled(props)

    const mainStatusId = status?.parentStatusId || status?.id

    return <DropdownButton
        disabled={isDisabled}
        open={open}
        id="select-status"
        title={getDropdownTitle()}
        onToggle={onToggle}
    >
        {contactStatuses.map(status => {
            if ( isBookingInBusinessUi(props) && status.superType === 'noShow' ) {
                return null
            }
            return (
              <MenuItem
                  active={status.id === mainStatusId}
                  eventKey={status.id}
                  key={status.id}
                  onSelect={eventKey => handleSelectStatus(eventKey)}
              >
              {status.name}
            </MenuItem>
            )
        })}
    </DropdownButton>


}