import { useState, useContext } from 'react'
import moment from 'moment';
import { getServicePoint } from "./utils"
import DatePicker from '../date-picker';
import { useLazyGetStatisticsForRepresentativeQuery } from '../../rtk/statistics-api';
import ContactStatusContext from './contact-status-context';

export const BookingDatePicker = ({ 
    date,
    props, 
    showBooking, 
    representative, 
    representativeHasMaxBookings,
    showCallback,
    setCallbackStartsAt,
    setUserModified
}) => {

    const context = useContext(ContactStatusContext)

    const [representativeDisabledWeeks, setRepresentativeDisabledWeeks] = useState([])

    const [getStatisticsForRepresentative, { isLoading, isFetching }] = useLazyGetStatisticsForRepresentativeQuery()

    const getDisabledRange = (props, showBooking, showCallback) => {
      const disabledRange = []
      if ( showBooking || showCallback ) {
        if ( showBooking && representative && !props.bookingId && representativeDisabledWeeks.length ) {
          disabledRange.push(day => representativeDisabledWeeks.includes(moment(day).week()))
        }
        if (( props.contact && 
          props.contact.project && 
          props.contact.project.disableBookingTimeRange 
          ) || props.project.disableBookingTimeRange 
        ) {

        } else {
          disabledRange.push({
            after: moment()
              .add(45, 'days')
              .toDate()  
          })
        }
      }
      return disabledRange
    }

    const handleDayChange = _date => {
        const selectedDate = moment(_date)
        const newDate = moment(date)
        newDate.set({
            date: selectedDate.date(),
            month: selectedDate.month(),
            year: selectedDate.year()
        });
        setCallbackStartsAt(newDate.toDate())
        setUserModified(true)
        if ( props.parent ) {
          context.setUserModified(true)
        }
    }    

    const updateRepresentativeDisabledWeeks = async (representativeId, date) => {
      if ( props.contact.project.businessCheckBookingValidity && !props.bookingId ) {
        const projectId = getServicePoint(props).projectId
        const params = {
          date: moment(date).startOf('month').toISOString(),
          endDate: moment(date).endOf('month').toISOString(),
          timezone: 'Europe/Helsinki',
          projectId
        }
        try {
          const stats = await getStatisticsForRepresentative({representativeId, params}).unwrap()
          const {maxBookingCount, n} = stats
          setRepresentativeDisabledWeeks(
              n.filter(a => a.count >= maxBookingCount)
                .map(a => a.week)
          )
        } catch ( e ) {
          console.error(e)
          setRepresentativeDisabledWeeks([])
        }
      }
    }  

    const handleMonthChange = month => {
      if ( showBooking && representative ) {
          const date = moment(month).startOf('day')
          updateRepresentativeDisabledWeeks(representative.id, date)
      }
    }

    const disabledRange = getDisabledRange(props, showBooking, showCallback)
    const datePickerDisabledRange = disabledRange 
      ? disabledRange.length > 1
          ? disabledRange
          : disabledRange[0]
      : undefined

    const datePickerStyleColor = {
      color: representativeHasMaxBookings && showBooking
        ? 'red' : 'unset'
    }

    return <DatePicker
          style={datePickerStyleColor}
          onMonthChange={month => handleMonthChange(month)}
          onDayChange={date => handleDayChange(date)}
          date={date}
          disabledRange={datePickerDisabledRange}
          isLoading={isLoading || isFetching}
          loadingComponent={
            <div style={{ 
              backgroundColor: "white", 
              border: "1px solid #d6d6d6", 
              borderRadius: 4,
              width: 175.5
            }}>
              <DatePickerSpinner />
            </div>
          }
      />

}

const DatePickerSpinner = () => {
  return <div>
    <div style={{ height: 75 }} className="busy-indicator">
      <div className="spinner" 
        style={{
          maxHeight: 50,
          maxWidth: 50,
          borderWidth: 10
        }}
      />
    </div>
  </div>
}