import { fillSmsInfo, fillEmailInfo, fillEmailTitleInfo, CONTACT_BOOKING_IDS } from '../../utils';
import moment from 'moment';

const BUSINESS_NOSHOW_BOOKING_IDS = [5, 6, 7, 10];

export function isBusinessBookingCallback(props, isCallback) {
    return props.project.customUserInterfaceId === 2 && isCallback;
}

export function isBusinessBookingContact(props, mainStatusId) {
    return props.project.customUserInterfaceId === 2 && mainStatusId === 2;
}

export function isBookingInBusinessUi(props) {
    return ['booker', 'teamLeader'].includes(props.user?.type) &&
        props.project?.customUserInterfaceId === 2 &&
        props.withBooking;
}

export function isBusinessBookingCancelled(props, bookingStatusId) {
    return isBookingInBusinessUi(props) && 
        BUSINESS_NOSHOW_BOOKING_IDS.includes(bookingStatusId);
}

export function isBooking(props) {
    return props.status?.superType === 'booking';
}

export function isBusinessBookingMade(props) {
    return isBookingInBusinessUi(props) && 
        isBooking(props) &&
        props.user.type === 'booker';
}

export function isBusinessBookingMadeOrCancelled(props) {
    return isBookingInBusinessUi(props) && 
        ( isBooking(props) || getMainStatusId(props) === 10 ) && 
        props.user.type === 'booker';
}

export function getMainStatusId(props) {
    return props.status !== undefined && props.status.isSubStatus
        ? props.status.parentStatusId
        : props.status?.id;
}

export function getSubStatusId(props, state) {
    return state.subStatusId !== undefined
        ? state.subStatusId
        : props.status !== undefined && props.status.id !== undefined
            ? props.status.id
            : undefined;
}

export function getAdjacentRepresentativesByRepresentativeId(representativeId, props) {
    return (props.servicePoints.find(
      servicePoint => servicePoint.representatives.find(
        representative => representative.id === representativeId
      )
    ) || {representatives: []}).representatives || [];
}

export function getCalendar(representative, props) {
    if (!representative) return null;
    const calendar = representative.calendars.find(
      calendar => calendar.projectId === getServicePoint(props).projectId
    );
    if (!calendar) {
      return representative.calendars.find(
        calendar => calendar.projectId === null
      );
    } else return calendar;
}

export function getServicePoint(props) {
    if (props.servicePoint && props.servicePoint.id) return props.servicePoint;
    else if (props.contact && props.contact.representativeId) {
      const servicePoint = props.servicePoints.find(
        servicePoint => servicePoint.representatives.find(
          representative => representative.id === props.contact.representativeId
        )
      );
      if (servicePoint) {
        return servicePoint;
      }
    }
    return {};
}

export function getSelectedStatusName(contactStatuses, mainStatusId, props) {
    let selectedStatus;

    if (props.status !== undefined) {
      if (mainStatusId !== undefined) {
        selectedStatus = contactStatuses.find(
          status => status.id === mainStatusId
        );
      } else {
        selectedStatus = contactStatuses.find(
          status => status.id === ( 
            isBookingInBusinessUi(props) && 
            props.statusId === 10 
          ) ? 6 : props.status.id
        );
      }

      if (selectedStatus === undefined) {
        return '';
      } else {
        return selectedStatus.name;
      }
    }
    return '';
}

export const getSubStatusesById = (parentStatusId, contactStatuses) => 
  contactStatuses.find(status => status.id === parentStatusId)?.subStatuses || []

export function getSubStatusName(statusId, contactStatuses) {
    for ( const contactStatus of contactStatuses ) { 
      if ( contactStatus.id === statusId ) {
        return contactStatus.name
      } else {
        for ( const subStatus of contactStatus.subStatuses ) {
          if ( subStatus.id === statusId ) {
            return subStatus.name
          }
        }
      }
    }
    return ''
}

export function formatRepresentative(representative, callbackStartsAt) {
    return {
        startsAt: callbackStartsAt,
        address: '',
        representative: {
            emailTemplate: representative ? representative.emailTemplate : null,
            smsTemplate: representative ? representative.smsTemplate : null,
            user: {
                firstName: representative ? (representative.user?.firstName || representative.name?.split(' ')[0]) : '',
                lastName: representative ? (representative.user?.lastName || representative.name?.split(' ')[1]) : '',
                phoneNumber: representative ? representative.phoneNumber : ''
            }
        }
    }
}

export function getEmailTitle(props, representative, callbackStartsAt) {
    return fillEmailTitleInfo(
        formatRepresentative(representative, callbackStartsAt), 
        props.project,
        props.client
    )
}

export function getEmailBody(props, representative, callbackStartsAt) {
    return fillEmailInfo(
        formatRepresentative(representative, callbackStartsAt), 
        props.project,
        props.client
    )
}

export function getTextMessage(props, representative, callbackStartsAt) {
    return fillSmsInfo(
        formatRepresentative(representative, callbackStartsAt), 
        props.project,
        props.client
    )
}

export function getSelectedSubArea(subAreaId, props, representative) {
    if ( subAreaId ) {
      return props.subAreas.find(
        subArea => subArea.id === subAreaId
      )
    } else {
      const representativeId = representative?.id
      const subArea = props.subAreas.find(subArea => {
        if ( subArea?.representatives.find(representative => representative.id === representativeId) ) {
          return subArea
        }
      })
      return subArea
    }
}

export function getSelectedServicePointForRepresentative(representativeId, props) {
    const servicePoint = props.servicePoints.find(servicePoint => {
      if ( servicePoint?.representatives.find(representative => representative.id === representativeId) ) {
        return servicePoint
      }
    })
    return servicePoint
}

export function getSelectedSubAreaForRepresentative(representativeId, props) {
    const subArea = props.subAreas.find(subArea => {
        if ( subArea?.representatives.find(representative => representative.id === representativeId) ) {
            return subArea
        }
    })
    return subArea
}

export function getSelectedSubAreaName(subAreaId, defaultName='', props, state) {
    const subArea = getSelectedSubArea(subAreaId, props, state)
    return subArea
      ? subArea.name
      : defaultName
  }

export function getRepresentativeName(representative) {
    return representative.name || `${representative.user?.firstName} ${representative.user?.lastName}`
}

export function getDefaultBookingTypeForRepresentative(representative, props, typeOfBooking) {
  const calendar = getCalendar(representative, props)
  if ( calendar ) {
    let type = typeOfBooking
    for ( let i = 0 ; i < 2 ; i ++ ) {
      if ( type === 'servicePoint' && !calendar.enableServicePoint ) {
        type = 'remote'
      }
      if ( type === 'remote' && !calendar.enableRemote ) {
        type = 'call'
      }
      if ( type === 'call' && !calendar.enableCall ) {
        type = 'online'
      }
      if ( type === 'online' && !calendar.enableOnline ) {
        type = 'servicePoint'
      }
    }
    return type
  } else {
    return 'servicePoint'
  }
}