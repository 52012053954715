import { DropdownButton, MenuItem } from 'react-bootstrap'
import { getCalendar } from './utils'

export const BookingTypeDropdown = ({
    props, 
    representative, 
    typeOfBooking, 
    setTypeOfBooking,
    disabled,
    t
}) => {

    const _disabled = disabled || ( !representative && !getCalendar(representative, props) )

    let title;
    if ( !representative || !getCalendar(representative, props) ) {
        title = ''
    } else {
        title = t(
            'booking.type' +
            typeOfBooking.substring(0, 1).toUpperCase() +
            typeOfBooking.substring(1)
        )
    }

    const calendar = getCalendar(representative, props)
    const items = []

    const getMenuItem = type => {
        return (
            <MenuItem
                active={typeOfBooking === type}
                eventKey={type}
                key={type}
                onSelect={typeOfBooking => setTypeOfBooking(typeOfBooking)}>
                    {t('booking.type' + type.substring(0, 1).toUpperCase() + type.substring(1))}
            </MenuItem>
        )
    }

    if ( calendar ) {
        if ( calendar.enableServicePoint ) {
            items.push(getMenuItem('servicePoint'))
        }
        if ( calendar.enableRemote ) {
            items.push(getMenuItem('remote'))
        }
        if ( calendar.enableCall ) {
            items.push(getMenuItem('call'))
        }
        if ( calendar.enableOnline ) {
            items.push(getMenuItem('online'))
        }
    }

    return <DropdownButton disabled={_disabled} id="select-type-of-booking" title={title}>
        { items }
    </DropdownButton>

}