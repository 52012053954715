import React from "react"
import moment from "moment"
import { useGetSuggestionsForRepresentativeQuery } from "../../rtk/representatives-api"

export const FreeTimeList = (props) => {
    return <div className="free-time-list">
      <FreeTimeListContent {...props} />
    </div>
}
  
const FreeTimeListContent = ({
    typeOfBooking, 
    representativeId, 
    currentDate,
    onSelect,
    projectId,
    skipFetch,
    returnEmptyList
  }) => {

    const params = {
        startsAtOrAfter: currentDate.startOf('day').format(),
        endsBefore: currentDate.endOf('day').format(),
        projectId
    }

    let { data: suggestions, isLoading, isFetching } = useGetSuggestionsForRepresentativeQuery(
        {representativeId, params},
        {skip: skipFetch, refetchOnMountOrArgChange: true}
    )

    isLoading = isLoading || isFetching

    if ( isLoading ) {
      return <FreeTimeListSpinner />
    } else if ( returnEmptyList || !suggestions?.length ) {
      return <NoFreeTimes />
    }
  
    const filteredSuggestions = suggestions.filter(value => 
      value.type === ( typeOfBooking === 'servicePoint' ? 'primary' : typeOfBooking ) && 
        value.representativeId === representativeId
    )

    if ( !filteredSuggestions.length ) {
      return <NoFreeTimes />
    }

    return filteredSuggestions.map((value, i) => {
        const startsAt = moment(value.startsAt)
        const endsAt = moment(value.endsAt)
        if ( currentDate.day() !== startsAt.day() ||
          currentDate.month() !== startsAt.month() ||
          currentDate.year() !== startsAt.year() ) {
            return null
          }
        const style = startsAt.hour() === currentDate.hour() &&
          startsAt.minute() === currentDate.minute()
            ? { color: 'white', backgroundColor: '#337ab7' } : {}
        return <div key={i} style={style} className="item" onClick={() => {
            const time = { hour: startsAt.hour(), minute: startsAt.minute() }
            onSelect(time)
          }}>
            { startsAt.format('HH:mm') } - { endsAt.format('HH:mm') }
        </div>
      })
  
}

const NoFreeTimes = () => {
  return <div style={{ margin: '4px 12px', color: '#aaa' }}>
    Ei vapaita aikoja.
  </div>
}

const FreeTimeListSpinner = () => {
    return <div>
      <div style={{ height: 75 }} className="busy-indicator">
        <div className="spinner" 
          style={{
            maxHeight: 50,
            maxWidth: 50,
            borderWidth: 10
          }}
        />
      </div>
    </div>
}