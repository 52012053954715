import { Modal, Button } from 'react-bootstrap'

export const BlocklistModal = ({ onClose, show, t }) => 
    <Modal onHide={onClose} show={show}>
        <Modal.Header closeButton={false}>
            <Modal.Title>{t('call.confirmAddToBlocklist')}</Modal.Title>
        </Modal.Header>
        <Modal.Footer>
            <Button onClick={() => onClose(true)}>{t('yes')}</Button>
            <Button onClick={() => onClose(false)}>{t('no')}</Button>
        </Modal.Footer>
    </Modal>
