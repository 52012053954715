import { useContext } from 'react'
import { DropdownButton, MenuItem } from 'react-bootstrap'
import ContactStatusContext from './contact-status-context'

export const BookingStatusesDropdown = ({
    props, 
    setUserModified,
    bookingStatusId,
    setBookingStatusId
}) => {

    const context = useContext(ContactStatusContext)

    const BUSINESS_NOSHOW_BOOKING_ID = 5
    const BUSINESS_NOSHOW_BOOKING_IDS = [5, 6, 7, 10]

    const bookingStatuses = ['Toteutuu', 'Peruuntui']
    
    const title = bookingStatuses[BUSINESS_NOSHOW_BOOKING_IDS.includes(bookingStatusId) ? 1 : 0]

    const onSelect = eventKey => {
        let _bookingStatusId = 0
        if ( eventKey ) {
            _bookingStatusId = BUSINESS_NOSHOW_BOOKING_ID
        } else {
            if ( BUSINESS_NOSHOW_BOOKING_IDS.includes(props.bookingStatusId) ) {
                _bookingStatusId = 1
            } else _bookingStatusId = props.bookingStatusId
        }
        setUserModified(true)
        setBookingStatusId(_bookingStatusId)
        if ( props.parent ) {
            context.setUserModified(true)
            context.setBookingStatusId(_bookingStatusId)
        }              
    }

    return <DropdownButton disabled={!props.bookingId} id="select-booking-status" title={title}>
        {bookingStatuses.map((outcome, i) => 
            <MenuItem
                active={BUSINESS_NOSHOW_BOOKING_IDS.includes(bookingStatusId) ? i === 1 : i === 0}
                eventKey={i}
                key={i}
                onSelect={onSelect}
            >
                {outcome}
            </MenuItem>
        )}
    </DropdownButton>

}