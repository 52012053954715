export const ContactNotes = ({addTopMargin, value, disabled, onChange}) => {

    const columnStyle = { marginTop: addTopMargin ? 8 : 0 }

    return <div className="info-row-column double" style={columnStyle}>
        <div className="heading" style={{height: 22}}>
            <span>Lisätiedot päämiehelle</span>
        </div>
        <textarea
            style={{ height: 107 }}
            disabled={disabled}
            onChange={event => onChange(event)}
            value={value || ''}
            placeholder='Lisätiedot...'
        />
    </div>

}