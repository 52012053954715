import classNames from 'classnames';
import moment from 'moment';
import React, { Component } from 'react';
import { Glyphicon, MenuItem, SplitButton, Modal, Button } from 'react-bootstrap';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { NOSHOW_ONLY, NOSHOW_TYPE } from '../../consts';
import * as bookingActions from '../../redux/booking/actions';
import * as callListActions from '../../redux/call-lists/actions';
import * as contactActions from '../../redux/contacts/actions';
import * as bookerActions from '../../redux/bookers/actions';

import { getPath } from '../../redux/utils/utils.saga';

import SokLogo from '../../assets/images/S_logo.min.svg'

const CONFIRMATION_ONLY = { ...NOSHOW_ONLY }

class CallType extends Component {
  handleClick = (event, contact, type) => {
    this.props.resetReservation();
    this.props.getContact(
      contact.id, 
      this.props.addBookingParticipants
    );
    let updateCallTypeLists = this.getUpdateCallTypeLists(type);
    if ( this.props.currentCallType != null ) {
      updateCallTypeLists = {
        ...updateCallTypeLists,
        ...this.getUpdateCallTypeLists(this.props.currentCallType)
      }
    }
    const oldType = this.props.currentCallType
    this.props.setCurrentCallType(type);
    this.props.getContactCard(
      contact.id,
      type,
      updateCallTypeLists,
      oldType
    );
  };

  getUpdateCallTypeLists = type => {
    if ( type === 'tentative' ) {
      return { tentative: true }
    } else if ( type === 'callback' ) {
      return { callback: true }
    } else if ( type === 'noShow' ) {
      return {
        noShow: {
          onlyOwn: this.props.onlyOwn,
          onlyOthers: this.props.onlyOthers,
          includeNotInterested: this.props.includeNotInterested,
          selectedNoShowServicePoint: this.props.selectedNoShowServicePoint,
          selectedNoShowPostalAreas: this.props.selectedNoShowPostalAreas,
          selectedNoShowRepresentatives: this.props.selectedNoShowRepresentatives,
          selectedNoShowBooker: this.props.selectedNoShowBooker,
          selectedNoShowYear: this.props.selectedNoShowYear,
          selectedNoShowMonth: this.props.selectedNoShowMonth,
          selectedNoShowStatus: this.props.selectedNoShowStatus,
          noShowSokContacts: this.props.noShowSokContacts,
        }
      }
    } else if ( type === 'confirmation' ) {
      return { confirmation: true }
    }
  }

  getMenuItem = (contact, type, noBottomBorder=false) => {
    const currentContact = (contact || {}).id === (this.props.contact || {}).id
    const itemStyle = currentContact ? {pointerEvents: 'none'} : {}
    if ( noBottomBorder ) itemStyle.borderBottom = 'none'
    /*if ( type === 'confirmation' )*/ itemStyle.height = 'auto'
    const style = currentContact ? {color: '#ccc'} : {}
    return (
      <MenuItem
        disabled={currentContact}
        style={itemStyle}
        eventKey={contact}
        key={contact.id}
        onClick={event => this.handleClick(event, contact, type)}
      >
        <div style={{display: 'flex', flexFlow: 'row', alignItems: 'center'}}>
          { contact.contactBusinessKnownType === 'sok' &&
            <div style={{ display: 'inline-block', marginRight: 5, flex: 0, padding: 0, opacity: currentContact ? .5 : 1 }}>
                <img src={SokLogo} alt="SOK" style={{
                  width: 14,
                  height: 14,
                  borderRadius: '50%'
                }}/>
            </div>
          }
          <div style={style}>
            {typeof contact.name === 'string' ? contact.name : '-'}
          </div>
        </div>
        <div style={style}className="date">
          {moment(contact.startTime).format('D.M.YYYY H:mm')} ({contact.campaignName})
        </div>
        {
          type === 'confirmation' &&
          <div style={style}>
            <FontAwesomeIcon icon={faPhone} style={{
              width: '8px',
              top: '12px',
              right: '24px'
            }}/>
            <span style={{ marginLeft: 4, fontSize: '.8em' }}>{ 
              contact.confirmationCalls.length 
                ? `${moment(contact.confirmationCalls[0].timestamp).format('DD.MM.YYYY HH:mm')} (${contact.confirmationCalls.length}.)`
                : '-'
            }</span>
          </div>
        }
      </MenuItem>
    );
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if ( this.props.confirmationOnlyOwn !== nextProps.confirmationOnlyOwn
        || this.props.confirmationScheduled !== nextProps.confirmationScheduled ) {
      this.props.patchConfirmationCallParameters({
        bookerId: nextProps.user.booker.id,
        params: {
          scheduled: nextProps.confirmationScheduled,
          onlyOwn: nextProps.confirmationOnlyOwn,
          booker: nextProps.selectedConfirmationBooker, 
          servicePoint: nextProps.selectedConfirmationServicePoint, 
          representatives: nextProps.selectedConfirmationRepresentatives
        }
      })
      this.props.getConfirmations({
        onlyOwn: nextProps.confirmationOnlyOwn,
        scheduled: nextProps.confirmationScheduled
      })
    }
  }

  isTeamLeader() {
    return this.props.user.type === 'teamLeader'
  }

  setCurrentCallType = (event, type, list, noToggle=false) => {
    if ( !list.length && type !== this.props.currentCallType ) {
      return;
    }
    if (noToggle || this.props.currentCallType !== type) {
      this.handleClick(event, list[0], type);
    } else {
      let oldType = this.props.currentCallType;
      this.props.setCurrentCallType(null);
      this.props.getContactCardNullType(
        this.getUpdateCallTypeLists(oldType)
      );
    }
  };

  isConfirmationFlowActive() {
    return ( !this.isTeamLeader() || this.props.confirmationScheduled ) &&
      this.props.currentCallType === 'confirmation' &&
      this.props.confirmations.length
  }

  hasExtraFilters() {
    return ( this.props.selectedNoShowServicePoint && this.props.selectedNoShowServicePoint.id ) ||
      ( this.props.selectedNoShowBooker && this.props.selectedNoShowBooker.id ) || 
      ( this.props.selectedNoShowPostalAreas && this.props.selectedNoShowPostalAreas.length ) ||
      ( this.props.selectedNoShowRepresentatives && this.props.selectedNoShowRepresentatives.length ) ||
        !(this.props.selectedNoShowYear === moment().year()) || this.props.selectedNoShowMonth
  }

  hasConfirmationExtraFilters() {
    return ( ( this.props.selectedConfirmationServicePoint && this.props.selectedConfirmationServicePoint.id ) || 
      ( this.props.selectedConfirmationBooker && this.props.selectedConfirmationBooker.id ) ||
      ( this.props.selectedConfirmationRepresentatives && this.props.selectedConfirmationRepresentatives.length > 0 ) )
  }

  setCurrentCallTypeNoShow = event => {
    this.setCurrentCallType(event, 'noShow', this.props.noShowBookings, true)
  }

  setCurrentCallTypeConfirmation = event => {
    this.setCurrentCallType(event, 'confirmation', this.props.confirmations, true)
  }

  projectIncludesSokContacts(project) {
    return (project?.contactBusinessTypes || []).find(
      contactBusinessType => contactBusinessType.knownType === 'sok'
    ) != null
  }

  projectHasSubAreasWithConfirmationCall(project) {
    return project?.hasSubAreasWithConfirmationCall
  }

  projectAllSubAreasWithConfirmationCall(project) {
    return project?.allSubAreasWithConfirmationCall
  }

  getDropdownTitle(t, n, id, current) {
    return <div className="dropdown-title">
      {this.props.t(t)} ({n})
      {this.props.isLoading[id] &&
        <div style={{ position: 'absolute', right: 8, top: 19}}>
          <div className="spinner-container" style={{ lineHeight: '26px' }}>
            <span className="spinner">
              <span className="bottom" style={{
                borderTopColor: current ? '#fff': '#ccc',
                borderWidth: 3
              }}></span>
            </span>
            <span className="loading"></span>
          </div>
        </div>
      }
    </div>
  }

  render() {
    const includesSokContacts = this.projectIncludesSokContacts(this.props.selectedProject)
    const hasSubAreasWithConfirmationCall = this.projectHasSubAreasWithConfirmationCall(this.props.selectedProject)
    const allSubAreasWithConfirmationCall = this.projectAllSubAreasWithConfirmationCall(this.props.selectedProject)
    
    const hideTentative = this.props.selectedProject?.noShowExceptions 
      && allSubAreasWithConfirmationCall
    
    const defaultModalStyle = {
      background: '#f4f4f4',
      color: 'black'
    }

    const sokModalStyle = {
      background: 'rgb(0, 170, 70)',
      color: 'white'
    }

    return (
      <div className={
          hasSubAreasWithConfirmationCall 
          && !process.env.REACT_APP_DISABLE_CONFIRMATION_CALL_TOOL 
            ? "call-type with-confirmation" 
            : "call-type"
        } 
        style={{ height: 'auto', paddingBottom: 5 }}>
        {
          !this.props.hideTentative &&
          !hideTentative &&
          <SplitButton
            disabled={this.isConfirmationFlowActive()}
            className={classNames({
              current: this.props.currentCallType === 'tentative'
            })}
            id="confirm-booking"
            key="confirm-booking"
            onClick={event => this.setCurrentCallType(event, 'tentative', this.props.tentatives)}
            title={this.getDropdownTitle('callType.tentative', this.props.tentatives.length, 'getTentatives', this.props.currentCallType === 'tentative')}
          >
            {this.props.tentatives.map(contact => {
              return this.getMenuItem(contact, 'tentative');
            })}
          </SplitButton>
        }
        {
          !this.props.hideCallback &&
          <SplitButton
            disabled={this.isConfirmationFlowActive()}
            className={classNames({
              current: this.props.currentCallType === 'callback'
            })}
            id="callback"
            key="callback"
            onClick={event => this.setCurrentCallType(event, 'callback', this.props.callbacks)}
            title={this.getDropdownTitle('callType.callback', this.props.callbacks.length, 'getCallbacks', this.props.currentCallType === 'callback')}
          >
            {this.props.callbacks.map((contact, i) => {
              const previousContact = this.props.callbacks[i - 1]
              const nextContact = this.props.callbacks[i + 1]
              const endOfDay = moment().endOf('day')
              if ( moment(contact.startTime).isAfter(endOfDay) 
                && ( !previousContact || moment(previousContact.startTime).isBefore(endOfDay) ) 
              ) {
                  return [
                    <div style={{ 
                      backgroundColor: 'rgb(238, 238, 238)', 
                      padding: '5px 15px 5px 10px',
                      position: 'sticky',
                      top: 0
                    }}>
                        {this.props.t('upcoming')}
                    </div>,
                    this.getMenuItem(contact, 'callback')
                  ]
              } else {
                return this.getMenuItem(
                  contact, 
                  'callback',
                  moment(contact.startTime).isBefore(endOfDay) 
                    && nextContact 
                    && moment(nextContact.startTime).isAfter(endOfDay)
                );
              }
            })}
          </SplitButton>
        }
        {
          !this.props.hideNoShow &&
          <SplitButton
            disabled={this.isConfirmationFlowActive()}
            className={classNames({
              current: this.props.currentCallType === 'noShow'
            })}
            id="no-show"
            key="no-show"
            onClick={event => this.setCurrentCallType(event, 'noShow', this.props.noShowBookings)}
            title={this.getDropdownTitle(
              'callType.noShow', 
              this.props.noShowBookings.length, 
              'getNoShowBookings', 
              this.props.currentCallType === 'noShow'
            )}
          >

          { includesSokContacts &&
            <MenuItem
                className="filter"
                eventKey={'noShowSokContacts'}
                key={'noShowSokContacts'}
                onClick={event => {
                  const value = !this.props.noShowSokContacts
                  this.props.setNoShowSokContacts(value);
                  this.props.getNoShowBookings(
                    this.props.onlyOwn,
                    this.props.includeNotInterested,
                    this.props.selectedNoShowServicePoint,
                    this.props.selectedNoShowPostalAreas,
                    this.props.selectedNoShowRepresentatives,
                    this.props.selectedNoShowBooker,
                    this.props.selectedNoShowYear,
                    this.props.selectedNoShowMonth,
                    this.props.selectedNoShowStatus,
                    value
                  );
                }}
              >
                <div>
                  {this.props.noShowSokContacts && <Glyphicon glyph="ok" />}
                  {this.props.t('callType.sokContacts')}
                </div>
              </MenuItem>
            }

            <MenuItem
              style={{ border: 0 }}
              className="filter"
              eventKey={'onlyOwn'}
              key={'onlyOwn'}
              onClick={event => {
                this.props.setOnlyOwn(NOSHOW_ONLY.own);
                this.props.getNoShowBookings(
                  this.props.onlyOwn === NOSHOW_ONLY.own
                    ? NOSHOW_ONLY.disabled
                    : NOSHOW_ONLY.own,
                  this.props.includeNotInterested,
                  this.props.selectedNoShowServicePoint,
                  this.props.selectedNoShowPostalAreas,
                  this.props.selectedNoShowRepresentatives,
                  this.props.selectedNoShowBooker,
                  this.props.selectedNoShowYear,
                  this.props.selectedNoShowMonth,
                  this.props.selectedNoShowStatus,
                  this.props.noShowSokContacts
                );
              }}
            >
              <div>
                {this.props.onlyOwn === NOSHOW_ONLY.own && <Glyphicon glyph="ok" />}
                {this.props.t('callType.onlyOwn')}
              </div>
            </MenuItem>
            <MenuItem
              className="filter"
              eventKey={'onlyOthers'}
              key={'onlyOthers'}
              onClick={event => {
                this.props.setOnlyOwn(NOSHOW_ONLY.others);
                this.props.getNoShowBookings(
                  this.props.onlyOwn === NOSHOW_ONLY.others
                    ? NOSHOW_ONLY.disabled
                    : NOSHOW_ONLY.others,
                  this.props.includeNotInterested,
                  this.props.selectedNoShowServicePoint,
                  this.props.selectedNoShowPostalAreas,
                  this.props.selectedNoShowRepresentatives,
                  this.props.selectedNoShowBooker,
                  this.props.selectedNoShowYear,
                  this.props.selectedNoShowMonth,
                  this.props.selectedNoShowStatus,
                  this.props.noShowSokContacts      
                );
              }}
            >
              <div>
                {this.props.onlyOwn === NOSHOW_ONLY.others && <Glyphicon glyph="ok" />}
                {this.props.t('callType.onlyOthers')}
              </div>
            </MenuItem>
            { !this.props.hideIncludeNotInterested &&
              <MenuItem
                className="filter"
                eventKey={'includeNotInterested'}
                key={'includeNotInterested'}
                onClick={event => {
                  this.props.setIncludeNotInterested();
                  this.props.getNoShowBookings(
                    this.props.onlyOwn,
                    !this.props.includeNotInterested,
                    this.props.selectedNoShowServicePoint,
                    this.props.selectedNoShowPostalAreas,
                    this.props.selectedNoShowRepresentatives,
                    this.props.selectedNoShowBooker,
                    this.props.selectedNoShowYear,
                    this.props.selectedNoShowMonth,
                    this.props.selectedNoShowStatus,
                    this.props.noShowSokContacts
                  );
                }}
              >
                <div>
                  {this.props.includeNotInterested && <Glyphicon glyph="ok" />}
                  {this.props.t('callType.includeNotInterested')}
                </div>
              </MenuItem>
            }
            <MenuItem
              className="filter"
              style={{ color: "#a51890" }}
              onClick={() => {
                this.props.redirect('/call/noshow-filters')
              }}
            >
              {this.hasExtraFilters() && <Glyphicon glyph="ok" />}
              {this.props.t('callType.moreFilters')}
            </MenuItem>
            {this.props.noShowBookings.map(contact => {
              return this.getMenuItem(contact, 'noShow');
            })}
          </SplitButton>
        }

       { !process.env.REACT_APP_DISABLE_CONFIRMATION_CALL_TOOL &&
        hasSubAreasWithConfirmationCall &&
        !this.props.hideConfirmation &&
        <SplitButton
          className={classNames({
            current: this.props.currentCallType === 'confirmation'
          })}
          id="confirmation"
          key="confirmation"
          onClick={event => {
            if ( !this.isConfirmationFlowActive() ) {
              this.setCurrentCallType(event, 'confirmation', this.props.confirmations)}
            }
          }
          title={this.getDropdownTitle('callType.confirmation', this.props.confirmations.length, 'getConfirmations', this.props.currentCallType === 'confirmation')}
        >

          { this.isTeamLeader() &&
            <MenuItem
              className="filter"
              style={{ color: "#a51890" }}
              onClick={() => {
                this.props.setConfirmationScheduled(
                  !this.props.confirmationScheduled
                );
              }}
            >
              {this.props.confirmationScheduled && <Glyphicon glyph="ok" />}
              {this.props.t('callType.scheduled')}
            </MenuItem>
          }
          { this.isTeamLeader() &&
            <MenuItem
              style={{ border: 0 }}
              className="filter"
              eventKey={'onlyOwn'}
              key={'onlyOwn'}
              onClick={event => {
                this.props.setConfirmationOnlyOwn(
                  this.props.confirmationOnlyOwn === CONFIRMATION_ONLY.own
                    ? CONFIRMATION_ONLY.disabled
                    : CONFIRMATION_ONLY.own
                );
              }}
            >
              <div>
                {this.props.confirmationOnlyOwn === CONFIRMATION_ONLY.own && <Glyphicon glyph="ok" />}
                {this.props.t('callType.onlyOwn')}
              </div>
            </MenuItem>
          }
          { this.isTeamLeader() &&
            <MenuItem
              className="filter"
              eventKey={'onlyOthers'}
              key={'onlyOthers'}
              onClick={event => {
                this.props.setConfirmationOnlyOwn(
                  this.props.confirmationOnlyOwn === CONFIRMATION_ONLY.others
                    ? CONFIRMATION_ONLY.disabled
                    : CONFIRMATION_ONLY.others
                );
              }}
            >
              <div>
                {this.props.confirmationOnlyOwn === CONFIRMATION_ONLY.others && <Glyphicon glyph="ok" />}
                {this.props.t('callType.onlyOthers')}
              </div>
            </MenuItem>
          }
          { this.isTeamLeader() &&
            <MenuItem
              className="filter"
              style={{ color: "#a51890" }}
              onClick={() => {
                this.props.redirect('/call/confirmation-filters')
              }}
            >
              {this.hasConfirmationExtraFilters() && <Glyphicon glyph="ok" />}
              {this.props.t('callType.moreFilters')}
            </MenuItem>
          }

          {this.props.confirmations.map(contact => {
            return this.getMenuItem(contact, 'confirmation');
          })}
        </SplitButton>
       }
        <Modal onHide={() => {
            if ( this.props.startConfirmation ) {
              this.props.setStartConfirmation(false)
            } else {
              this.props.setStartNoShowModal(null)
            }
          }} show={this.props.startConfirmation || this.props.startNoShowModal}>
          <Modal.Header closeButton={false} style={
              includesSokContacts && ( this.props.startConfirmation || this.props.startNoShowModal === NOSHOW_TYPE.sok )
                ? sokModalStyle : defaultModalStyle}>
            <Modal.Title>
              { this.props.startConfirmation 
                ? ( includesSokContacts 
                    ? this.props.t('callType.sokConfirmationBegins')
                    : this.props.t('callType.confirmationBegins')
                ) 
                : (
                  this.props.startNoShowModal === NOSHOW_TYPE.sok
                    ? this.props.t('callType.sokNoShowFlowBegins')
                    : this.props.t('callType.noShowFlowBegins')
                )
              }
            </Modal.Title>
          </Modal.Header>
          <Modal.Footer>
            <Button onClick={() => {
              if ( this.props.startConfirmation ) {
                this.props.setStartConfirmation(false)
              } else {
                this.props.setStartNoShowModal(null)
              }
            }}>
              {this.props.t('ok')}
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  callbacks: state.callLists.callbacks || [],
  confirmations: state.callLists.confirmations || [],
  contact: state.contacts.contact,
  contactStatus: state.contacts.contact.status,
  currentCallType: state.callLists.current,
  tentatives: state.callLists.tentatives || [],
  noShowBookings: state.callLists.noShowBookings || [],
  includeNotInterested: state.callLists.includeNotInterested,
  onlyOwn: state.callLists.onlyOwn,
  selectedNoShowServicePoint: state.callLists.selectedNoShowServicePoint,
  selectedNoShowPostalAreas: state.callLists.selectedNoShowPostalAreas,
  selectedNoShowRepresentatives: state.callLists.selectedNoShowRepresentatives,
  selectedNoShowYear: state.callLists.selectedNoShowYear,
  selectedNoShowMonth: state.callLists.selectedNoShowMonth,
  selectedNoShowStatus: state.callLists.selectedNoShowStatus,
  selectedNoShowBooker: state.callLists.selectedNoShowBooker,
  selectedConfirmationServicePoint: state.callLists.selectedConfirmationServicePoint,
  selectedConfirmationRepresentatives: state.callLists.selectedConfirmationRepresentatives,
  selectedConfirmationBooker: state.callLists.selectedConfirmationBooker,
  noShowSokContacts: state.callLists.noShowSokContacts,
  selectedProject: state.call.selectedProject,
  confirmationOnlyOwn: state.callLists.confirmationOnlyOwn,
  confirmationScheduled: state.callLists.confirmationScheduled,
  startConfirmation: state.callLists.startConfirmation,
  startNoShowModal: state.callLists.startNoShowModal,
  isLoading: state.callLists.isLoading,
  user: state.user
});

const mapDispatchToProps = dispatch => ({
  getBooking: bookingId => {
    dispatch(bookingActions.getBooking({ bookingId }));
  },
  getContact: (contactId, includeBookingParticipants) => {
    dispatch(contactActions.getContact({ contactId, includeBookingParticipants }));
  },
  getContactCard: (contactId, type, updateCallTypeLists, oldType) => {
    dispatch(contactActions.getContactCard({
      contactId, type, updateCallTypeLists, oldType
    }));
    dispatch(push(getPath('/call')));
  },
  getConfirmations: params => {
    dispatch(callListActions.getConfirmations(params))
  },
  getNoShowBookings: (
    onlyOwn, 
    includeNotInterested, 
    selectedNoShowServicePoint, 
    selectedNoShowPostalAreas,
    selectedNoShowRepresentatives,
    selectedNoShowBooker,
    selectedNoShowYear,
    selectedNoShowMonth,
    selectedNoShowStatus,
    noShowSokContacts
  ) => {
    dispatch(
      callListActions.getNoShowBookings({
        onlyOwn,
        includeNotInterested,
        selectedNoShowServicePoint,
        selectedNoShowPostalAreas,
        selectedNoShowRepresentatives,
        selectedNoShowBooker,
        selectedNoShowYear,
        selectedNoShowMonth,
        selectedNoShowStatus,
        noShowSokContacts
      })
    );
  },
  redirect: url => {
    dispatch(push(url));
  },
  resetReservation: () => {
    dispatch(bookingActions.resetReservation());
  },
  setCurrentCallType: type => {
    dispatch(callListActions.setCurrentCallType(type));
  },
  setIncludeNotInterested: () => {
    dispatch(callListActions.setIncludeNotInterested());
  },
  setOnlyOwn: type => {
    dispatch(callListActions.setOnlyOwn(type));
  },
  setConfirmationOnlyOwn: type => {
    dispatch(callListActions.setConfirmationOnlyOwn(type));
  },
  setConfirmationScheduled: scheduled => {
    dispatch(callListActions.setConfirmationScheduled(scheduled));
  },
  getContactCardNullType: updateCallTypeLists => {
    dispatch(contactActions.getContactCard({ updateCallTypeLists }));
    dispatch(push(getPath('/call')));
  },
  setStartConfirmation: value => {
    dispatch(callListActions.setStartConfirmation(value))
  },
  setStartNoShowModal: value => {
    dispatch(callListActions.setStartNoShowModal(value))
  },
  patchConfirmationCallParameters(params) {
    dispatch(bookerActions.patchConfirmationCallParameters(params))
  },
  setNoShowSokContacts(value) {
    dispatch(callListActions.setNoShowSokContacts(value))
  }
});

export default connect(mapStateToProps, mapDispatchToProps, null, {forwardRef: true})(
  translate('translations', {withRef: true})(CallType)
);
