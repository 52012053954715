import { useRef, useContext } from 'react'
import { isBusinessBookingCancelled, isBusinessBookingMade } from "./utils"
import ReactTags from 'react-tag-autocomplete'
import ContactStatusContext from './contact-status-context'

export const ParticipantTags = ({props, bookingStatusId, title, placeholder, includeSwitch}) => {

    const context = useContext(ContactStatusContext)

    const reactTags = useRef(null)

    const tags = props.tags || []
    const people = props.people || []    

    const isBusinessBookingCancelledOrMade = isBusinessBookingCancelled(props, bookingStatusId) || isBusinessBookingMade(props)
    
    const columnClassName = 'info-row-column double' + (tags.length ? '' : ' no-tags')
    const columnStyle = { width: '90%' }

    const rowStyle = {
        width: '100%',
        borderBottom: 0
    }

    const headingStyle = {
        fontSize: 14,
        marginTop: 10,
        paddingBottom: 6
    }
    const tagsWrapperStyle = isBusinessBookingCancelledOrMade || 
        ( includeSwitch && props.switchChecked ) ? {
            pointerEvents: 'none', 
            cursor: 'not-allowed', 
            opacity: .5
        } : {} 

    const suggestions = isBusinessBookingCancelledOrMade ? [] : 
        people.filter(person => person.id >= 0)
        .filter(person => !tags.find(tag => tag.name === person.name))

    const suggestionsTransform = (query, suggestions) =>
        suggestions.filter(
            suggestion => 
              suggestion.name.toLowerCase()
                .indexOf(query.toLowerCase()) >= 0
          )

    const onDelete = i => {
        if ( !props.tags.length || isBusinessBookingCancelledOrMade ) return
        const tags = props.tags.slice(0)
        /*const tag = */tags.splice(i, 1)
        if ( props.parent ) {
            context.setTags(tags)
        }
    }
    
    const onAddition = tag => {
        if ( isBusinessBookingCancelledOrMade ) return
        const tags = [].concat(props.tags, tag)
        if ( props.parent ) {
            context.setTags(tags)
        }
    }

    const toggleSwitch = e => {
        context.setSwitchChecked(!props.switchChecked)
    }

    return <div className="info-row" style={rowStyle}>
      <div className={columnClassName} style={columnStyle}>
        <div className="heading" style={headingStyle}>{ title }</div>
        <div style={tagsWrapperStyle}>
          <ReactTags
            maxSuggestionsLength={1000}
            ref={reactTags}
            tags={tags}
            suggestions={suggestions}
            onDelete={onDelete}
            onAddition={onAddition}
            placeholderText={tags.length ? '' : placeholder}
            minQueryLength={0}
            suggestionsTransform={suggestionsTransform}
          />
        </div>
        { 
            includeSwitch && 
            <SwitchCheckBox 
                checked={props.switchChecked} 
                onChange={e => toggleSwitch(e)} 
            /> 
        }
      </div>
    </div>
}

const SwitchCheckBox = ({checked, onChange}) => {
    const headingStyle = {
        fontSize: 14,
        fontWeight: 'normal',
        marginTop: 2,
        paddingBottom: 6
    }
    const spanStyle = {
        cursor: 'pointer'
    }
    return <div className="heading" style={headingStyle}>
        <input name="switch" type="checkbox" checked={checked} onChange={onChange} />
        <span style={spanStyle} onClick={onChange}>Vaihde</span>
    </div>
}