import { DropdownButton, MenuItem } from 'react-bootstrap';
import React from 'react';
import { getSubStatusesById } from './utils';

export const BookingSubStatusesDropdown = ({onSelect, contactStatuses, status}) => {

    const mainStatusId = status?.parentStatusId || status?.id

    const title = status.isSubStatus ? status.name : ''

    return <DropdownButton id="select-sub-status" title={title}>
        {getSubStatusesById(mainStatusId, contactStatuses).map(_status => <MenuItem
                active={_status.id === status.id}
                eventKey={_status}
                key={_status.id}
                onSelect={status => onSelect(status)}
            >
                {_status.name}
            </MenuItem>
        )}
    </DropdownButton>
}