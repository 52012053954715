/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/*eslint-disable jsx-a11y/click-events-have-key-events */
import DayPicker, { DateUtils } from 'react-day-picker';
import moment from 'moment';
import 'moment/locale/fi';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import MomentLocaleUtils from 'react-day-picker/moment';

import 'react-day-picker/lib/style.css';

const overlayStyle = {
  background: 'white',
  position: 'absolute',
  zIndex: 999,
  boxShadow: "0 6px 12px rgba(0, 0, 0, 0.175)",
  borderRadius: 6
};

class DatePicker extends Component {
  constructor(props) {
    super(props);

    this.clickedInside = false;
    this.clickTimeout = null;
    this.daypicker = null;
    this.input = null;

    this.handleContainerMouseDown = this.handleContainerMouseDown.bind(this);
    this.handleDayClick = this.handleDayClick.bind(this);
    this.handleInputBlur = this.handleInputBlur.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleInputFocus = this.handleInputFocus.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleTodayClick = this.handleTodayClick.bind(this);

    this.state = {
      selectedDay: this.props.date,
      showOverlay: false,
      value: ''
    };
  }

  componentWillUnmount() {
    clearTimeout(this.clickTimeout);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!moment(nextProps.date).isSame(moment(this.props.date))) {
      this.setState({
        selectedDay: nextProps.date,
        showOverlay: false,
        value: ''
      });
    }
  }

  handleContainerMouseDown() {
    this.clickedInside = true;
    // The input's onBlur method is called from a queue right after onMouseDown event.
    // setTimeout adds another callback in the queue, but is called later than onBlur event
    this.clickTimeout = setTimeout(() => {
      this.clickedInside = false;
    }, 0);
  }

  handleDayClick(day, { disabled }) {
    if (disabled) {
      return;
    }

    this.setState({
      value: moment(day).format('L'),
      selectedDay: day,
      showOverlay: false
    });
    this.input.blur();
    this.props.onDayChange(day);
  }

  handleInputBlur() {
    const showOverlay = this.clickedInside;

    this.setState({
      showOverlay
    });

    // Force input's focus if blur event was caused by clicking on the calendar
    if (showOverlay) {
      this.input.focus();
    }
  }

  handleInputFocus() {
    if ( this.props.onMonthChange ) {
      this.props.onMonthChange(moment(this.props.selectedDay).startOf('month'))
    }
    this.setState({
      showOverlay: true
    });
  }

  handleInputChange(event) {
    const { value } = event.target;
    const momentDay = moment(value, 'L', true);
    if (momentDay.isValid()) {
      this.setState(
        {
          selectedDay: momentDay.toDate(),
          value
        },
        () => {
          this.daypicker.showMonth(this.state.selectedDay);
        }
      );
    } else {
      this.setState({ value, selectedDay: null });
    }
  }

  handleKeyDown(event) {
    // Blur with Esc key.
    if (event.keyCode === 27) {
      this.input.blur();
    }
  }

  handleTodayClick() {
    const newDate = moment().toDate();
    this.setState({
      selectedDay: moment(newDate).toDate()
    });
    this.input.blur();
    this.props.onDayChange(newDate);
  }

  render() {
    const chevronRotateStyle = {};
    if (this.state.showOverlay) {
      chevronRotateStyle.transform = 'rotate(180deg)';
    }

    return (
      <div
        className="date-picker-container"
        onKeyDown={event => this.handleKeyDown(event)}
        onMouseDown={this.handleContainerMouseDown}
      >
        <div
          className={this.state.showOverlay ? 'date-picker focus' : 'date-picker'}
          onBlur={this.handleInputBlur}
          style={this.props.style || {}}
          onClick={() => {
            if ( this.state.showOverlay ) {
              this.handleInputBlur()
            } else this.handleInputFocus()
          }}
          ref={element => {this.input = element}}
          tabIndex="0"
        >
          <span
            onChange={this.handleInputChange}
            style={{ verticalAlign: 'middle', userSelect: 'none' }}
          >
            {this.props.displayFormat !== undefined
              ? moment(this.state.selectedDay).format(this.props.displayFormat)
              : moment(this.state.selectedDay).format('D.M')}
          </span>
        </div>

        {this.state.showOverlay && (
          <div style={overlayStyle}>
            {
              this.props.isLoading &&
              this.props.loadingComponent
            }
            <div style={{ 
              display: this.props.isLoading 
                ? "none" 
                : "unset",
            }}>
              <DayPicker
              onMonthChange={month => {
                if ( this.props.onMonthChange ) {
                  this.props.onMonthChange(month)
                }
              }}
              disabledDays={this.props.disabledRange}
              firstDayOfWeek={1}
              locale={'fi'}
              localeUtils={MomentLocaleUtils}
              onDayClick={this.handleDayClick}
              ref={component => {
                this.dayPicker = component;
              }}
              selectedDays={day =>
                DateUtils.isSameDay(this.state.selectedDay, day)
              }
              showOutsideDays
            />
            </div>
            {this.props.todayButton && (
              <div className="datepicker today" onClick={this.handleTodayClick}>
                Tänään
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

DatePicker.propTypes = {
  date: PropTypes.instanceOf(Date).isRequired,
  disabledRange: PropTypes.object,
  displayFormat: PropTypes.string,
  onDayChange: PropTypes.func.isRequired,
  todayButton: PropTypes.bool
};

export default DatePicker;
