export const SendEmailInvitations = ({
    props,
    disabled,
    emailTitle,
    emailBody,
    sendEmail,
    setEmailTitle,
    setEmailBody,
    setSendEmail
}) => {

    return <div className="info-row-column double">
        <div className="heading">
            <input
                name="sendEmail"
                type="checkbox"
                disabled={disabled || props.reservation === null }
                checked={ props.reservation !== null && sendEmail }
                onChange={() => setSendEmail(!sendEmail)}
            />
            <span style={{cursor: props.reservation === null ? 'not-allowed' : 'pointer'}} onClick={() => {
                if ( props.reservation !== null ) {
                    setSendEmail(!sendEmail)
                }
            }}>
                Lähetä osallistujille sähköpostikutsu
            </span>
        </div>
        <input 
            placeholder={'Otsikko'}
            disabled={disabled || props.reservation === null || !sendEmail}
            style={{ fontSize: '13px', width: '95%', border: '1px solid #ccc', padding: 5, borderRadius: 2, marginBottom: 2 }} 
            onChange={event => setEmailTitle(event.target.value)}
            value={emailTitle || ''}
        />
        <textarea
            disabled={disabled || props.reservation === null || !sendEmail}
            onChange={event => setEmailBody(event.target.value)}
            placeholder={'Viesti...'}
            value={emailBody || ''}
        />
    </div>

}