import { useState, useContext, useEffect } from 'react'
import moment from 'moment'
import TimePicker from '../../components/time-picker'
import { getServicePoint } from "./utils"
import { FreeTimeList } from './free-time-list'

import ContactStatusContext from './contact-status-context'

import { useGetRepresentativeBookingTimeValidationQuery } from '../../rtk/representatives-api'

export const BookingTimePicker = ({ 
  props,
  disabled, 
  showBooking, 
  representativeHasMaxBookings,
  representative,
  setCallbackStartsAt,
  date=moment().toDate(),
  setUserModified,
  typeOfBooking,
  setInvalidTime
}) => {

    const { projectId } = getServicePoint(props)

    let { data: invalidTime, isLoading: isInvalidTimeLoading, isFetching: isInvalidTimeFetching } = useGetRepresentativeBookingTimeValidationQuery(
      { 
        representativeId: representative?.id,
        params: {
          startsAt: moment(date).startOf('minute').toISOString(), 
          type: typeOfBooking,
          projectId
        }
      }, 
      { skip: !(
          showBooking 
          && date
          && props.contact.project.businessCheckBookingValidity 
          && representative
        ), refetchOnMountOrArgChange: true }
    )

    invalidTime = !!invalidTime?.result
    isInvalidTimeLoading = isInvalidTimeLoading || isInvalidTimeFetching

    useEffect(() => {
      setInvalidTime(invalidTime)
    }, [invalidTime])

    const context = useContext(ContactStatusContext)

    const [showFreeTimeList, setShowFreeTimeList] = useState(false)
    const [timePicker, setTimePicker] = useState(null)

    const timePickerWrapperStyle = { 
      color: !disabled && ( invalidTime || representativeHasMaxBookings ) ? 'red' : 'inherit', 
      width: '75%', 
      position: 'relative' 
    }

    const timePickerStyle = { minWidth: '100%' }

    const handleTimeChange = time => {
        // Preserve selected date
        const newDate = moment(date)

        newDate.set({
          hour: time.hour,
          minute: time.minute
        })

        if ( timePicker ) {
          const value = moment(newDate).format('HH:mm')
          timePicker.handleChange({target: {value}})
          timePicker.input.value = value
        }

        setCallbackStartsAt(newDate.toDate())
        setUserModified(true)

        if ( props.parent ) {
          context.setUserModified(true)
        }
    }    
    
    const onFocus = () => {
      if ( props.contact.project.businessCheckBookingValidity ) {
        setShowFreeTimeList(true)
      }
    }

    const onBlur = e => {
      if ( props.contact.project.businessCheckBookingValidity ) {
        e.persist()
        setTimeout(() => {
          setShowFreeTimeList(false)
          if ( e.target ) e.target.blur()
        }, 250)
        e.preventDefault()
      }
    }

    if ( showBooking ) {
      return (
        <div style={timePickerWrapperStyle}>
          <TimePicker
            style={timePickerStyle}
            onRef={ref => setTimePicker(ref)}
            onFocus={e => onFocus(e)}
            onBlur={e => onBlur(e)}
            disabled={disabled}
            date={date}
            onTimeChange={time => handleTimeChange(time)}
          />
        { showFreeTimeList && representative &&
          <FreeTimeList typeOfBooking={typeOfBooking}
            representativeId={representative.id}
            currentDate={moment(date)}
            projectId={projectId}
            skipFetch={!showBooking || !representative || !props.contact.project.businessCheckBookingValidity}
            returnEmptyList={!( representative && ( props.bookingId || !representativeHasMaxBookings ) )}
            onSelect={time => {
              handleTimeChange(time)
              setShowFreeTimeList(false)
            }}
          />
        }
        </div>

      )
    } else {
      return (
        <TimePicker
            disabled={disabled}
            date={date}
            onTimeChange={time => handleTimeChange(time)}
        />
      )
    }

}