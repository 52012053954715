import { api } from "./api"

export const contactsApi = api.injectEndpoints({
	endpoints: (builder) => ({
        getContactStatuses: builder.query({
			query: opts => {
                const { withBooking, contactId, callType } = opts

                const params = {}
                if ( withBooking ) params.withBooking = true
                if ( callType === 'confirmation' ) params.confirmation = true

                const url = contactId 
                    ? `/contacts/${contactId}/statuses` 
                    : "/contact-statuses"

                return {
                    url,
                    params,
                }
			},
            providesTags: (res, err, opts) => [{ 
                type: "ContactStatuses", 
                id: opts.contactId || "all" 
            }]
		}),
	})
})

export const { 
	useGetContactStatusesQuery
} = contactsApi