import { createContext } from 'react';

export default createContext({
    userModified: false,
    setUserModified: () => {},
    savePending: false,
    setSavePending: () => {},
    switchChecked: false,
    setSwitchChecked: () => {},
    subStatus: null,
    setSubStatus: () => {},
    tags: null,
    setTags: () => {},
    status: null,
    setStatus: () => {},
    bookingStatusId: null,
    setBookingStatusId: () => {},
    isSaveDisabled: false,
    setIsSaveDisabled: () => {},
})
  
export const getContactStatusContextProviderValues = _this => {
    return {
        userModified: _this.state.userModified,
        setUserModified: userModified => _this.setState({userModified}),
        savePending: _this.state.savePending,
        setSavePending: savePending => _this.setState({savePending}),
        switchChecked: _this.state.switchChecked,
        setSwitchChecked: switchChecked => _this.setState({switchChecked}),
        subStatus: _this.state.subStatus,
        setSubStatus: subStatus => _this.setState({subStatus}),
        tags: _this.state.tags,
        setTags: tags => _this.setState({tags}),
        status: _this.state.status,
        setStatus: status => _this.setState({status}),
        bookingStatusId: _this.state.bookingStatusId,
        setBookingStatusId: bookingStatusId => _this.setState({bookingStatusId}),
        isSaveDisabled: _this.state.isSaveDisabled,
        setIsSaveDisabled: isSaveDisabled => _this.setState({isSaveDisabled}),
    }
}