import { api } from "./api"

export const statisticsApi = api.injectEndpoints({
	endpoints: (builder) => ({
        getStatisticsForServicePoint: builder.query({
            query: ({servicePointId, params}) => ({
                url: `/statistics/service-point/${servicePointId}`,
                params
            }),
            providesTags: (res, err, opts) => [{ 
                type: "ServicePointStatistics", 
                id: opts.servicePointId
            }]
		}),
        getStatisticsForRepresentative: builder.query({
            query: ({representativeId, params}) => ({
                url: `/statistics/${representativeId}`,
                params
            }),
            providesTags: (res, err, opts) => [{ 
                type: "RepresentativeStatistics", 
                id: opts.representativeId
            }]
		})
	})
})

export const { 
	useLazyGetStatisticsForServicePointQuery,
	useLazyGetStatisticsForRepresentativeQuery
} = statisticsApi