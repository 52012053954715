export const SendSmsConfirmations = ({
    disabled, 
    textMessage, 
    setTextMessage, 
    sendTextMessage, 
    setSendTextMessage
}) => {
    return <div className="info-row-column double">
        <div className="heading" style={{height: 22}}>
            <input
                disabled={disabled}
                name="sendTextMessage"
                type="checkbox"
                checked={sendTextMessage}
                onChange={() => setSendTextMessage(!sendTextMessage)}
            />
            <span style={{cursor: 'pointer'}} onClick={() => setSendTextMessage(!sendTextMessage)}>
                Lähetä osallistujille vahvistus tekstiviestillä
            </span>
        </div>
        <textarea
            style={{ height: 107 }}
            disabled={disabled || !sendTextMessage}
            onChange={event => setTextMessage(event.target.value)}
            placeholder={'Viesti...'}
            value={textMessage || ''}
        />
    </div>
}