import { ParticipantTags } from './participant-tags'
import { BookingTypeDropdown } from './booking-type-dropdown'
import { RepresentativeDropdown } from './representative-dropdown'
import { ServicePointDropdown } from './service-point-dropdown'
import { SubAreaDropdown } from './sub-area-dropdown'

export const RepresentativeColumn = ({
    props, 
    className, 
    t, 
    disabled, 
    typeOfBooking, 
    setTypeOfBooking,
    servicePoint,
    setServicePoint,
    representative,
    setRepresentative,
    bookingStatusId,
    subArea,
    setSubArea,
    callbackStartsAt
}) => {

    const headingStyle = { 
        fontSize: 14, 
        marginTop: 8 
    }

    return <div className={className}>
        <div style={{ display: 'flex', flexFlow: 'row' }}>
            <div style={{ flex: 1 }}>
                <div className="heading" style={headingStyle}>
                    {t('representative.representative')}
                </div>
                {
                  props.project.businessAllowServicePointChange && 
                  <>
                    <LocationDropdown props={props} 
                        disabled={disabled} 
                        typeOfBooking={typeOfBooking}
                        setTypeOfBooking={setTypeOfBooking}
                        servicePoint={servicePoint}
                        setServicePoint={setServicePoint}
                        subArea={subArea}
                        setSubArea={setSubArea}
                        setRepresentative={setRepresentative}
                        representative={representative}
                        component={props.project.mergeServicePoints 
                            ? SubAreaDropdown 
                            : ServicePointDropdown
                        }
                    />
                    <div style={{ marginBottom: 10 }}></div>
                  </>
                }
                <RepresentativeDropdown props={props}
                    disabled={disabled}
                    typeOfBooking={typeOfBooking}
                    setTypeOfBooking={setTypeOfBooking}
                    representative={representative}
                    subArea={subArea}
                    setRepresentative={setRepresentative}
                    callbackStartsAt={callbackStartsAt}
                />
            </div>
            <div style={{ flex: 1 }}>
                <div className="heading" style={headingStyle}>{t('booking.typeOfBooking')}</div>
                <BookingTypeDropdown props={props}
                    disabled={disabled}
                    t={t}
                    representative={representative}
                    typeOfBooking={typeOfBooking}
                    setTypeOfBooking={setTypeOfBooking}
                />
            </div>
        </div>
        { 
            !props.project.businessAllowServicePointChange && 
            props.callFlow &&
            <ParticipantTags
                bookingStatusId={bookingStatusId}
                props={props}
                title={'Osallistujat'}
                placeholder={'Lisää osallistujia...'}
                includeSwitch={false}
            />
        }
    </div>

}

const LocationDropdown = ({component, ...props}) => component(props)